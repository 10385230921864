<template>
  <Transition name="fade" mode="out-in">
    <!-- This should work just fine, because content passed inside slot should have v-if/v-show -->
    <!-- eslint-disable-next-line vue/require-toggle-inside-transition -->
    <slot></slot>
  </Transition>
</template>

<style>
.fade-enter-active {
  transition: all 0.1s ease-in-out;
}

.fade-leave-active {
  transition: all 0.1s ease-in-out;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
