import type { AxiosRequestConfig } from "axios"
import { defineStore } from "pinia"
import { computed, ref } from "vue"
import { MAX_SAFE_INTEGER } from "@/commons/composables/use-request"
import { scheduleSentryAction } from "@/commons/plugins/sentry"
import type { PaginatedResponse } from "@/commons/typings/api"
import { http } from "@/commons/utils/axios-instances"

const REFRESH_INTERVAL_MS = 1000 * 10 // 10 seconds

export type Notification = {
  code: string
  data: {}
  id: number
  occuredAt: string
  read: boolean
  readAt: string
}

const fetchNotifications = async (options: AxiosRequestConfig) => {
  const response = await http.get<PaginatedResponse<Notification>>("/me/notifications", options)
  return response.data
}

const scheduleFetchUnreadNotifications = async (
  onSuccess: (notifications: Notification[]) => void,
) => {
  try {
    const data = await fetchNotifications({
      params: {
        read: false,
        size: MAX_SAFE_INTEGER,
      },
    })
    onSuccess(data.content)

    setTimeout(() => {
      scheduleFetchUnreadNotifications(onSuccess)
    }, REFRESH_INTERVAL_MS)
  } catch (err) {
    console.error(err)
    scheduleSentryAction((instance) => instance.captureException(err))
  }
}

export const useNotificationsStore = defineStore("notifications", () => {
  const unreadNotifications = ref<Notification[]>([])
  const readNotifications = ref<Notification[]>([])
  const isFetchingReadNotifications = ref(false)
  const hasMoreReadNotifications = ref(true)

  const hasUnreadNotifications = computed(() => unreadNotifications.value.length > 0)

  const fetchReadNotifications = async (page: number = 0) => {
    try {
      isFetchingReadNotifications.value = true
      const data = await fetchNotifications({ params: { read: true, page } })

      hasMoreReadNotifications.value = !data.last

      if (page === 0) {
        readNotifications.value = data.content
        return
      }

      readNotifications.value = [...readNotifications.value, ...data.content]
    } catch (err) {
      console.error(err)
      scheduleSentryAction((instance) => instance.captureException(err))
    } finally {
      isFetchingReadNotifications.value = false
    }
  }

  const markAsRead = async (notification: Notification) => {
    try {
      await http.post(`/me/notifications/read`, undefined, {
        params: { notificationId: notification.id },
      })
      // Remove from unread notifications
      unreadNotifications.value = unreadNotifications.value.filter((n) => n.id !== notification.id)

      // Add to read notifications
      const sortedNotifications = [...readNotifications.value, notification].sort((a, b) =>
        b.occuredAt.localeCompare(a.occuredAt),
      )
      readNotifications.value = sortedNotifications
    } catch (err) {
      console.error(err)
      scheduleSentryAction((instance) => instance.captureException(err))
    }
  }

  const markAllAsRead = async () => {
    try {
      await http.post(`/me/notifications/read`)

      unreadNotifications.value = []

      await fetchReadNotifications()
    } catch (err) {
      console.error(err)
      scheduleSentryAction((instance) => instance.captureException(err))
    } finally {
      isFetchingReadNotifications.value = false
    }
  }

  scheduleFetchUnreadNotifications((notifications) => {
    unreadNotifications.value = notifications
  })

  fetchReadNotifications()

  return {
    unreadNotifications,
    readNotifications,
    isFetchingReadNotifications,
    hasUnreadNotifications,
    hasMoreReadNotifications,
    markAsRead,
    markAllAsRead,
    fetchReadNotifications,
  }
})
