<script setup lang="ts">
import PrimeAvatar from "primevue/avatar"
import type { AvatarProps, AvatarSlots } from "primevue/avatar"
import { ColorHash } from "@/commons/utils/color-hash"

type BaseUserInfo = {
  avatar?: string
  firstName?: string
  lastName?: string
  username: string
}

const props = withDefaults(
  defineProps<
    AvatarProps & {
      user?: BaseUserInfo
    }
  >(),
  {
    user: undefined,
    shape: "circle",
  },
)

defineSlots<AvatarSlots>()

const getUserInitials = (user: BaseUserInfo) => {
  if (user.firstName && user.lastName) {
    return `${user.firstName[0]}${user.lastName[0]}`.toUpperCase()
  }

  return user.username[0].toUpperCase()
}

const colorHash = new ColorHash({
  hue: { min: 90, max: 360 },
})
</script>

<template>
  <prime-avatar v-if="user?.avatar" v-bind="{ ...$attrs, ...props }" :image="user.avatar" />
  <prime-avatar
    v-else-if="(user?.firstName && user?.lastName) || user?.username"
    v-bind="{ ...$attrs, ...props }"
    class="text-white"
    :label="getUserInitials(user)"
    :style="{ background: colorHash.hslCSS(user.username) }"
  />
  <prime-avatar
    v-else
    class="secondary text-white bg-dark-50"
    v-bind="{ ...$attrs, ...props }"
    icon="i-mdi-account-outline"
  />
</template>
