import { z } from "zod"

export const keysToZodEnum = <T extends Record<string, any>>(obj: T) =>
  Object.keys(obj) as unknown as [keyof T, ...(keyof T)[]]

export const zodUniqueArray = <ArrSchema extends z.ZodArray<z.ZodTypeAny, "many">, UniqueVal>(
  uniqueBy: (item: z.infer<ArrSchema>[number]) => UniqueVal,
  schema: ArrSchema,
) =>
  schema.superRefine((items, ctx) => {
    const seen = new Set<UniqueVal>()
    for (const [i, item] of items.entries()) {
      const val = uniqueBy(item)
      if (seen.has(val)) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: `Unique property validation failed`,
          path: [i],
        })
      } else {
        seen.add(val)
      }
    }
  }) as unknown as ArrSchema // experimental casting to not lose the original type
