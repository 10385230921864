<script setup lang="ts">
import { computed } from "vue"
import type { RouteLocationRaw } from "vue-router"
import UiBadge from "@/ui/atoms/UiBadge.vue"
import SidebarMenuItemBase from "./SidebarMenuItemBase.vue"

const props = defineProps<{
  title: string
  icon: string
  to?: RouteLocationRaw
  badge?: boolean | number
}>()

const emits = defineEmits<{
  (e: "click"): void
}>()

const emitAndRedirect = (navigateFn: () => any) => {
  emits("click")
  navigateFn()
}

const shouldShowBadge = computed(() => {
  if (!props.badge) return false
  if (typeof props.badge === "number") return props.badge > 0
  return props.badge
})

const badgeValue = computed(() => {
  if (!props.badge || typeof props.badge === "boolean") return undefined
  if (props.badge > 9) return "9+"
  return props.badge
})
</script>

<template>
  <template v-if="to">
    <router-link :to="to" custom>
      <template #default="{ href, navigate, isActive }">
        <sidebar-menu-item-base
          tag="a"
          :title="title"
          :icon="icon"
          :href="href"
          :is-active="isActive"
          @click="emitAndRedirect(navigate)"
        >
          <ui-badge
            v-if="shouldShowBadge"
            severity="danger"
            blink
            class="absolute top-3px right-3px z-1"
            :value="badgeValue"
          />
          <i :class="icon" class="text-3xl p-overlay-badge" />
          <span class="text-xs text-center">{{ title }}</span>
        </sidebar-menu-item-base>
      </template>
    </router-link>
  </template>
  <template v-else>
    <sidebar-menu-item-base tag="button" :title="title" :icon="icon" @click="$emit('click')">
      <ui-badge
        v-if="shouldShowBadge"
        severity="danger"
        blink
        class="absolute top-3px right-3px z-1"
        :value="badgeValue"
      />
      <i :class="icon" class="text-3xl" />
      <span class="text-xs text-center">{{ title }}</span>
    </sidebar-menu-item-base>
  </template>
</template>

<style scoped lang="scss"></style>
