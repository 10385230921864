import { z } from "zod"
import { CurrencyCodes } from "@/commons/typings/currencies"
import { CountryCodeSchema } from "@/commons/utils/countries"
import { PriceSchema } from "@/marketplace/typings/price"
import { CarbonProjectTypeSchema, ImageSchema } from "./carbon-project"
import { VintageClaimTypeSchema, VintageCreditTypeSchema, VintageImpactTypeSchema } from "./vintage"

export const CartProductType = z.enum(["VINTAGE_CREDITS", "DUE_DILIGENCE_REPORT"])

export type CartProductTypeType = z.infer<typeof CartProductType>

const BaseCartItemSchema = z.object({
  id: z.number(),
  quantity: z.number(),
  unitPrice: PriceSchema,
  unitPricePreDiscount: PriceSchema,
  price: PriceSchema,
  pricePreDiscount: PriceSchema,
  currency: CurrencyCodes,
  reservedTo: z.string().datetime(),
})

export const CartItemDDReportSchema = BaseCartItemSchema.extend({
  productType: z.literal(CartProductType.Enum.DUE_DILIGENCE_REPORT),
  product: z.object({
    reportId: z.number(),
    reportName: z.string(),
    projects: z.array(
      z.object({
        id: z.number(),
        name: z.string(),
        countries: z.array(CountryCodeSchema),
        registry: z.object({
          id: z.number(),
          name: z.string(),
        }),
        projectType: CarbonProjectTypeSchema,
      }),
    ),
  }),
})

export type CartItemDDReportSchemaType = z.infer<typeof CartItemDDReportSchema>

export const CartItemVintageSchema = BaseCartItemSchema.extend({
  productType: z.literal(CartProductType.Enum.VINTAGE_CREDITS),
  product: z.object({
    id: z.number(),
    projectId: z.number(),
    projectName: z.string(),
    countries: z.array(CountryCodeSchema),
    registry: z.object({
      id: z.number(),
      name: z.string(),
    }),
    projectType: CarbonProjectTypeSchema,
    images: z.array(ImageSchema),
    yearFrom: z.number(),
    yearTo: z.number(),
    impactType: VintageImpactTypeSchema,
    claimType: VintageClaimTypeSchema,
    methodology: z.string(),
    creditType: VintageCreditTypeSchema,
  }),
})

export type CartItemVintageSchemaType = z.infer<typeof CartItemVintageSchema>

export const CartItemSchema = z.discriminatedUnion("productType", [
  CartItemDDReportSchema,
  CartItemVintageSchema,
])

export type CartItemSchemaType = z.infer<typeof CartItemSchema>

export const CartSchema = z.object({
  id: z.number(),
  userId: z.number(),
  items: z.array(CartItemSchema),
  credits: z.number(),
  currency: CurrencyCodes,
  price: z.object({
    net: z.number(),
    gross: z.number(),
    vatAmount: z.number(),
  }),
  pricePreDiscount: z.object({
    net: z.number(),
    gross: z.number(),
    vatAmount: z.number(),
  }),
  vatPercentage: z.number(),
  version: z.number(),
})

export type CartSchemaType = z.infer<typeof CartSchema>

export const CartErrorCodesSchema = z.enum(["NOT_SAME_CURRENCY"])

export type CartErrorCodes = z.infer<typeof CartErrorCodesSchema>
