<script setup lang="ts">
import PrimeConfirmDialog from "primevue/confirmdialog"
import PrimeConfirmPopup from "primevue/confirmpopup"
import PrimeDynamicDialog from "primevue/dynamicdialog"
import Toast from "primevue/toast"
import { inject } from "vue"
import { useRoute } from "vue-router"
import { useTheme } from "@/commons/composables/use-theme"
import LayoutDefault from "@/commons/layouts/LayoutDefault.vue"
import { isInitializedSymbol } from "@/main"

const route = useRoute()
const isInitialized = inject(isInitializedSymbol)

const { injectStyles } = useTheme()
injectStyles()
</script>

<template>
  <component :is="route.meta.layout || LayoutDefault" v-if="isInitialized" />
  <toast />
  <prime-dynamic-dialog />
  <prime-confirm-popup group="popup" />
  <prime-confirm-dialog group="dialog" />
</template>

<style lang="scss">
@layer normalize, primevue, ui;

:root {
  --animation-duration__expand: 0.3s;
}

@layer normalize {
  * {
    margin: 0;
    padding: 0;
    min-width: 0;
  }

  // Vuetify's modified styles
  button,
  input,
  select,
  textarea {
    background-color: transparent;
    border-style: none;
  }

  // Reset heading weights
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: normal;
  }
}

@layer primevue {
  .p-confirm-popup-message {
    white-space: pre-line;
  }
}

body {
  font-family: var(--font-family);
  color: var(--color-dark);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--font-family-heading);
}

/* Used in Prime's DataTable to align header to the right */
/* Please see @/commons/utils/primevue.ts */
.prime-datatable-header-right .p-datatable-column-header-content {
  justify-content: flex-end !important;

  .p-datatable-column-title {
    text-align: right;
  }
}
</style>
