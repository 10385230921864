<script setup lang="ts">
import PrimeProgressSpinner from "primevue/progressspinner"
import type { ProgressSpinnerProps } from "primevue/progressspinner"

const props = withDefaults(defineProps<ProgressSpinnerProps>(), {})
</script>

<template>
  <prime-progress-spinner
    v-bind="{ ...$attrs, ...props }"
    style="width: 50px; height: 50px"
    :pt="{
      spinner: { style: { animationDuration: '2s' } },
      circle: {
        style: {
          stroke: 'var(--color-primary)',
          strokeWidth: 3,
          animation: 'p-progress-spinner-dash 1.5s ease-in-out infinite',
        },
      },
    }"
  />
</template>
