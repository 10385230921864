import type { LoginErrorCodes } from "@/auth/typings/login"
import type { PasswordResetErrorCodes } from "@/auth/typings/password-reminder"
import type { RegistrationErrorCodes } from "@/auth/typings/registration"
import type { PortfolioType } from "@/dashboard/typings/dashboard"
import type { FAQTopic, FAQCategory } from "@/faq/answers"
import type { ManagementCarbonProjectStatusSchemaType } from "@/management/typings/carbon-project"
import type { ManagementDdReportErrorCodesType } from "@/management/typings/dd-reports"
import type {
  CarbonProjectStatusSchemaType,
  CarbonProjectTypeSchemaType,
  CarbonProjectSortPrioritySchemaType,
} from "@/marketplace/typings/carbon-project"
import type { CartErrorCodes } from "@/marketplace/typings/cart"
import type { OrderStatusSchemaType } from "@/marketplace/typings/order"
import type { RetirementStatusSchemaType } from "@/marketplace/typings/retirement"
import type {
  VintageClaimTypeSchemaType,
  VintageCreditTypeSchemaType,
  VintageImpactTypeSchemaType,
  VintageStatusSchemaType,
} from "@/marketplace/typings/vintage"
import type { MexicoProvincesType } from "../utils/provinces"
import type { GetNonPrimitiveKeys, GetPrimitiveKeys } from "./../typings/helpers"

export type LocaleSchema = {
  commons: {
    GENERIC_ERROR: string
    YES: string
    NO: string
  }
  auth: {
    errors: Record<LoginErrorCodes | PasswordResetErrorCodes | RegistrationErrorCodes, string>
    invitation: {
      SUCCESS_TOOLTIP: string
    }
  }
  orders: {
    status: Record<OrderStatusSchemaType, string>
  }
  retirements: {
    status: Record<RetirementStatusSchemaType, string>
  }
  carbonProjects: {
    projectStatus: Record<
      CarbonProjectStatusSchemaType | ManagementCarbonProjectStatusSchemaType,
      string
    >
    projectType: Record<CarbonProjectTypeSchemaType, string>
    sortPriority: Record<CarbonProjectSortPrioritySchemaType, string>
  }
  vintages: {
    impactType: Record<VintageImpactTypeSchemaType, string>
    claimType: Record<VintageClaimTypeSchemaType, string>
    creditType: Record<VintageCreditTypeSchemaType, string>
    status: Record<VintageStatusSchemaType, string>
  }
  ddReports: {
    ADDED_TO_CART: string
    REMOVED_FROM_CART: string
  }
  management: {
    fileUpload: {
      UPLOAD_IN_PROGRESS: string
      UPLOAD_FAILED: string
      UPLOAD_SUCCESS: string
    }
    ddReports: {
      errors: Record<ManagementDdReportErrorCodesType, string>
      UPDATE_TOAST: string
      deleteReportConfirmation: {
        TEXT: string
      }
    }
  }
  faq: {
    categories: Record<FAQCategory, string>
    topics: Record<FAQTopic<FAQCategory>, string>
  }
  dashboard: {
    portfolioTypes: Record<PortfolioType, string>
  }
  cart: {
    errors: Record<CartErrorCodes, string>
  }
  provinces: {
    MX: Record<MexicoProvincesType, string>
  }
}

export type LocaleSchemaKeys = GetPrimitiveKeys<LocaleSchema>

export type LocaleSchemaNonPrimitiveKeys = GetNonPrimitiveKeys<LocaleSchema>

export const en: LocaleSchema = {
  commons: {
    GENERIC_ERROR: "Something went wrong. Please try again later.",
    YES: "Yes",
    NO: "No",
  },
  auth: {
    errors: {
      INVALID_PASSWORD: "The username or password you have entered is invalid.",
      UNVERIFIED: "Your account is not verified. Please check your email for verification link.",
      BLOCKED: "Your account is blocked. If you think this is a mistake, please contact us.",
      INVALID_TOKEN: "Invalid token. Please try again.",
      TOKEN_EXPIRED: "Token expired. Please try again.",
      INVALID_PASSWORD_CHANGE: "Your password does not meet the requirements.",
      ALREADY_REGISTERED: "This email is already registered. Please use another email.",
    },
    invitation: {
      SUCCESS_TOOLTIP: "Your account has been created successfully. You can now log in.",
    },
  },
  orders: {
    status: {
      AWAITING_APPROVAL: "Awaiting approval",
      AWAITING_PAYMENT: "Awaiting payment",
      PAID: "Paid",
      COMPLETED: "Completed",
      REJECTED: "Rejected",
    },
  },
  retirements: {
    status: {
      RETIREMENT_IN_PROGRESS: "Retirement in progress",
      RETIRED: "Retired",
      RETIRED_CERTIFICATED: "Retired",
    },
  },
  carbonProjects: {
    projectStatus: {
      CREDIT_SALE: "Credit sale",
      SPONSORING: "Sponsoring",
      FINANCING: "Financing",
      MANAGED: "Managed",
      OFFTAKE_AGREEMENT: "Offtaking",
    },
    projectType: {
      AFFORESTATION_REFORESTATION_REVEGETATION: "Afforestation, reforestation, revegetation",
      AGROFORESTRY: "Agroforestry",
      BIOCHAR: "Biochar",
      ENHANCED_ROCK_WEATHERING: "Enhanced rock weathering",
      IMPROVED_AGRICULTURAL_LAND_MANAGEMENT: "Agricultural land management",
      IMPROVED_FOREST_MANAGEMENT: "Forest management",
      LAND_RESTORATION: "Land restoration",
      CLEAN_ENERGY: "Clean energy",
      WASTE_MANAGEMENT: "Waste management",
      SUSTAINABLE_LIVESTOCK: "Sustainable livestock",
      UNKNOWN: "Unknown",
    },
    sortPriority: {
      TOP: "Top",
      MIDDLE: "Middle",
      BOTTOM: "Bottom",
    },
  },
  vintages: {
    claimType: {
      CONTRIBUTION: "Contribution claim",
      COMPENSATION: "Compensation claim",
    },
    impactType: {
      REMOVAL: "Removal",
      REDUCTION: "Reduction",
      MIXED: "Mixed",
    },
    creditType: {
      EX_POST: "Ex-post",
      EX_ANTE: "Ex-ante",
      FUTURE_EX_POST: "Future ex-post",
    },
    status: {
      AVAILABLE: "Available",
      TEMPORARILY_UNAVAILABLE: "Temporarily unavailable",
      SOLD_OUT: "Sold out",
      CONTACT_REQUIRED: "Contact required",
      COMING_SOON: "Coming soon",
      EXTERNAL: "External",
    },
  },
  ddReports: {
    ADDED_TO_CART: "Report added to cart",
    REMOVED_FROM_CART: "Report removed from cart",
  },
  management: {
    fileUpload: {
      UPLOAD_IN_PROGRESS: "Uploading file...",
      UPLOAD_FAILED: "File upload failed, please try again.",
      UPLOAD_SUCCESS: "File uploaded successfully.",
    },
    ddReports: {
      errors: {
        FILE_MISSING: "You need to add a file to make the report available.",
        PROJECT_ALREADY_USED_BY_ANOTHER_REPORT:
          "One of the projects is already used by another report. Please select different project.",
      },
      UPDATE_TOAST: "Report saved successfully",
      deleteReportConfirmation: {
        TEXT: `Are you sure you want to delete this version of the report?`,
      },
    },
  },
  faq: {
    categories: {
      COMMERCIAL_TERMS: "Commercial Terms",
      PRODUCT_INFORMATION: "Product Information",
      TECHNICAL_ASSISTANCE: "Technical Assistance",
    },
    topics: {
      ACCESS_AND_USER_MANAGEMENT: "Access & User Management",
      PRICING: "Pricing",
      PRODUCT_JARGON: "Product Jargon",
      PRODUCT_MANAGEMENT: "Product Management",
      PRODUCT_OFFERING: "Product Offering",
      PRODUCT_QUALITY: "Product Quality",
      PURCHASE_AND_PAYMENT: "Purchase & Payment",
      RETURNS: "Returns",
    },
  },
  dashboard: {
    portfolioTypes: {
      EX_ANTE: "Ex-ante",
      EX_POST: "Ex-post",
      RETIRED: "Retired",
    },
  },
  cart: {
    errors: {
      NOT_SAME_CURRENCY: "You can't add products with different currencies to the cart.",
    },
  },
  provinces: {
    MX: {
      AGUASCALIENTES: "Aguascalientes",
      BAJA_CALIFORNIA: "Baja California",
      BAJA_CALIFORNIA_SUR: "Baja California Sur",
      CAMPECHE: "Campeche",
      CHIAPAS: "Chiapas",
      CHIHUAHUA: "Chihuahua",
      COAHUILA: "Coahuila",
      COLIMA: "Colima",
      DURANGO: "Durango",
      GUANAJUATO: "Guanajuato",
      GUERRERO: "Guerrero",
      HIDALGO: "Hidalgo",
      JALISCO: "Jalisco",
      MEXICO_CITY: "Mexico City",
      MEXICO_STATE: "Mexico State",
      MICHOACAN: "Michoacan",
      MORELOS: "Morelos",
      NAYARIT: "Nayarit",
      NUEVO_LEON: "Nuevo Leon",
      OAXACA: "Oaxaca",
      PUEBLA: "Puebla",
      QUERETARO: "Queretaro",
      QUINTANA_ROO: "Quintana Roo",
      SAN_LUIS_POTOSI: "San Luis",
      SINALOA: "Sinaloa",
      SONORA: "Sonora",
      TABASCO: "Tabasco",
      TAMAULIPAS: "Tamaulipas",
      TLAXCALA: "Tlaxcala",
      VERACRUZ: "Veracruz",
      YUCATAN: "Yucatan",
      ZACATECAS: "Zacatecas",
    },
  },
}
