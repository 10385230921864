import type { AxiosRequestConfig } from "axios"
import { cloneDeep } from "lodash-es"
import { ref, onUnmounted, computed, toValue } from "vue"
import type { UnwrapRef, ComputedRef } from "vue"
import type { PaginatedResponse } from "../typings/api"
import { http } from "../utils/axios-instances"

export const MAX_SAFE_INTEGER = 2147483647

type UseRequestProps<T> = AxiosRequestConfig<T> & {
  url: string
  postUrl?: string
  immediate?: boolean
}

type UseRequestOptions<T> = UseRequestProps<T> | ComputedRef<UseRequestProps<T>>

export const useRequest = <T>(optionsMaybeRef: UseRequestOptions<T>) => {
  const options = toValue(optionsMaybeRef)
  const data = ref<T | undefined>(options.data ? cloneDeep(options.data) : undefined)
  const error = ref<Error | undefined>(undefined)
  const loading = ref<boolean>(false)
  const controllers = new Map<string, AbortController>()

  const call = async (config?: AxiosRequestConfig) => {
    const options = {
      ...toValue(optionsMaybeRef),
      ...config,
    }
    const method = options.method || "GET"

    controllers.get(method)?.abort()
    const controller = new AbortController()
    controllers.set(method, controller)

    try {
      loading.value = true

      const response = await http.request<T>({
        ...options,
        signal: controller.signal,
      })

      data.value = cloneDeep(response.data) as UnwrapRef<T>

      return response
    } catch (err) {
      if (!(err instanceof Error)) return
      if (err.name === "CanceledError") {
        console.warn("Request canceled")
        return
      }

      error.value = err
      throw err
    } finally {
      loading.value = false
    }
  }

  const get = () => call()
  const post = (postData?: T) =>
    call({
      method: "POST",
      data: postData || data.value,
      url: toValue(optionsMaybeRef).postUrl || toValue(optionsMaybeRef).url,
    })

  onUnmounted(() => {
    const abortControllers = Array.from(controllers.values())

    abortControllers.forEach((controller) => controller.abort())
  })

  if (options.immediate) {
    get()
  }

  return { data, error, loading, get, post, call }
}

type UseRequestPOSTProps<T> = Omit<UseRequestProps<T>, "method" | "data">

type UseRequestPOSTOptions<T> = UseRequestPOSTProps<T> | ComputedRef<UseRequestPOSTProps<T>>

export const useRequestPOST = <T>(options: UseRequestPOSTOptions<T>) => {
  const { error, loading, call } = useRequest<T>(options)

  const post = (data: T) => call({ method: "POST", data })

  return { error, loading, post }
}

export const useRequestWithPagination = <T>(options: UseRequestOptions<PaginatedResponse<T>>) => {
  const computedOptions = computed(() => {
    const unwrappedOptions = toValue(options)

    return {
      ...unwrappedOptions,
      params: {
        size: MAX_SAFE_INTEGER,
        ...unwrappedOptions.params,
      },
    }
  })
  const { data, error, loading, get } = useRequest<PaginatedResponse<T>>(computedOptions)

  // TODO: Implement pagination

  const pageData = computed(() => data.value?.content)
  const totalElements = computed(() => data.value?.totalElements)

  return { data: pageData, totalElements, error, loading, get }
}
