import { z } from "zod"

const customErrorMap: z.ZodErrorMap = (error, ctx) => {
  // eslint-disable-next-line sonarjs/no-small-switch -- we will support more cases in the future
  switch (error.code) {
    case z.ZodIssueCode.too_small:
      return { message: `This field must contain at least ${error.minimum} character(s)` }
  }

  return { message: ctx.defaultError }
}

export const zodPlugin = () => {
  z.setErrorMap(customErrorMap)
}
