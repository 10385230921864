import { z } from "zod"

export const LoginSchema = z.object({
  username: z.string().email(),
  password: z.string().min(10),
})

export type LoginSchema = z.infer<typeof LoginSchema>

export type LoginResponseTokens = {
  authToken: string
  refreshToken: string
}

export const LoginErrorCodeSchema = z.enum(["INVALID_PASSWORD", "UNVERIFIED", "BLOCKED"])

export type LoginErrorCodes = z.infer<typeof LoginErrorCodeSchema>
