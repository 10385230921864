import { useAuth } from "@/auth/composables/use-auth"
import { useTokens } from "@/auth/composables/use-tokens"
import { initializeRouterHooks } from "./router"

export const initializeApp = async () => {
  const { isLoggedIn, initializeUser, logout } = useAuth()

  initializeRouterHooks()

  if (isLoggedIn.value) {
    try {
      const { refreshTokens } = useTokens()

      await refreshTokens()
      initializeUser()
    } catch (error) {
      logout()
    }
  }
}
