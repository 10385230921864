<script setup lang="ts">
import PrimeDivider from "primevue/divider"
import type { DividerProps, DividerSlots } from "primevue/divider"

const props = withDefaults(defineProps<DividerProps>(), {})

const slots = defineSlots<DividerSlots>()
</script>

<template>
  <prime-divider v-bind="{ ...$attrs, ...props }">
    <template v-for="(_, slotName) in slots" #[slotName]>
      <slot :name="slotName" />
    </template>
  </prime-divider>
</template>
