import { jwtDecode } from "jwt-decode"

type DecodedTokenPayload<T> = {
  exp: number
  iat: number
  iss: string
  jti: string
} & T

export const decodeJwtToken = <T>(token: string): DecodedTokenPayload<T> => {
  try {
    return jwtDecode(token) as DecodedTokenPayload<T>
  } catch (error) {
    throw new Error("Invalid JWT token")
  }
}

export const isTokenValid = (token: string): boolean => {
  try {
    const now = Date.now() / 1000
    const payload = decodeJwtToken(token)
    const { exp } = payload

    return exp > now
  } catch (error) {
    return false
  }
}
