import { z } from "zod"
import { CurrencyCodes } from "@/commons/typings/currencies"
import { PriceSchema } from "@/marketplace/typings/price"

export const VintageSourceSchema = z.enum(["PURCHASED", "EXTERNAL", "MANAGED"])
export type VintageSourceSchemaType = z.infer<typeof VintageSourceSchema>

export const VintageImpactTypeSchema = z.enum(["REMOVAL", "REDUCTION", "MIXED"])
export type VintageImpactTypeSchemaType = z.infer<typeof VintageImpactTypeSchema>

export const VintageClaimTypeSchema = z.enum(["CONTRIBUTION", "COMPENSATION"])
export type VintageClaimTypeSchemaType = z.infer<typeof VintageClaimTypeSchema>

export const VintageCreditTypeSchema = z.enum(["EX_POST", "EX_ANTE", "FUTURE_EX_POST"])
export type VintageCreditTypeSchemaType = z.infer<typeof VintageCreditTypeSchema>

export const VintageStatusSchema = z.enum([
  "AVAILABLE",
  "TEMPORARILY_UNAVAILABLE",
  "SOLD_OUT",
  "CONTACT_REQUIRED",
  "COMING_SOON",
  "EXTERNAL",
])
export type VintageStatusSchemaType = z.infer<typeof VintageStatusSchema>

export const VintageSchema = z.object({
  id: z.number().optional(),
  yearFrom: z.number().min(1990).max(2200),
  yearTo: z.number().min(1990).max(2200),
  impactType: VintageImpactTypeSchema,
  claimType: VintageClaimTypeSchema,
  status: VintageStatusSchema,
  methodology: z.string(),
  availableCredits: z.number().min(0),
  unitPrice: PriceSchema,
  currency: CurrencyCodes,
  creditType: VintageCreditTypeSchema,
})

export type VintageSchema = z.infer<typeof VintageSchema>

export const VintageOfftakeSchema = z.object({
  yearFrom: z.number().min(1990).max(2200),
  yearTo: z.number().min(1990).max(2200),
  availableCredits: z.number().gte(1),
  impactType: VintageImpactTypeSchema,
  prepayment: z
    .number()
    .lte(1, "Prepayment cannot be higher than 100%")
    .gte(0, "Prepayment cannot be lower than 0%"),
  creditType: z.literal(VintageCreditTypeSchema.enum.FUTURE_EX_POST),
  unitPrice: z.number().gte(1),
  currency: CurrencyCodes,
})
export type VintageOfftakeSchemaType = z.infer<typeof VintageOfftakeSchema>
