import { defineStore } from "pinia"
import type { z } from "zod"
import { scheduleSentryAction } from "@/commons/plugins/sentry"
import { UserBaseSchema } from "../typings/user"
import type { UserPermission, UserRole } from "../typings/user"

const UserStoreStateSchema = UserBaseSchema.omit({
  role: true,
  organization: true,
})

type UserStoreState = z.infer<typeof UserStoreStateSchema> & {
  role: UserRole
  permissions: Set<UserPermission>
  organizationId: number
}

export const useUserStore = defineStore("user", {
  state: (): UserStoreState => ({
    username: "",
    firstName: "",
    lastName: "",
    avatar: "",
    organizationId: 0,
    role: "" as UserRole, // this will be overwritten by the setUser action
    permissions: new Set(),
  }),
  getters: {
    fullName: (state): string => `${state.firstName} ${state.lastName}`,
    isLoggedIn: (state): boolean => !!state.username,
    isAdmin: (state): boolean => state.role === "Admin",
  },
  actions: {
    setUser(user: UserBaseSchema) {
      const { role, ...restUserData } = user
      const permissionsSet = new Set(role.permissions)

      this.$patch({
        ...restUserData,
        permissions: permissionsSet,
        role: role.name as UserRole,
      })

      scheduleSentryAction((instance) => {
        instance.setUser({
          id: user.id ? user.id.toString() : undefined,
          username: user.username,
          email: user.username,
          extras: {
            permissions: Array.from(permissionsSet),
            role: role,
          },
        })
      })
    },
    hasRole(role: UserRole) {
      return this.role === role
    },
    hasPermission(permission: UserPermission) {
      return this.permissions.has(permission)
    },
  },
})
