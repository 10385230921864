import type { Theme } from "@/commons/typings/theme"
import headerBackground from "./img/header-background.jpg"
import logo from "./img/logo.svg"

const volkswagenTheme: Theme = {
  logo,
  headerBackground,
  importCustomStyles: () => import("./styles/index.css"),
  chartColors: [
    "#002832",
    "#038c82",
    "#c1fd05",
    "#00fdff",
    "#37d1df",
    "#ecf9fe",
    "#738a94",
    "#f0fcfa",
    "#2a4756",
    "#b0d8e5",
    "#e8e8e8",
  ],
  cssVariables: {
    "--color-primary": "#038c82",
    "--color-secondary": "#c1fd05",
    "--color-tertiary": "#00fdff",
    "--color-dark": "#002832",
    "--color-on-primary": "#fff",
    "--color-on-secondary": "#000",
    "--color-on-tertiary": "#000",
    "--color-on-dark": "#fff",
    "--font-family": "vw-text, Helvetica, Arial, sans-serif",
    "--font-family-heading": "vw-head, Helvetica, Arial, sans-serif",
    "--font-family-mono": '"Chivo Mono", monospace',
    "--header-gradient":
      "linear-gradient(90deg, color-mix(in srgb, var(--color-primary) 55%, transparent), color-mix(in srgb, var(--color-primary) 15%, transparent))",
  },
  primevueTheme: {
    semantic: {
      // https://m2.material.io/design/color/the-color-system.html#tools-for-picking-colors
      primary: {
        50: "#e1f2f2",
        100: "#b3e0de",
        200: "#81cdca",
        300: "#4eb8b3",
        400: "#27a9a2",
        500: "#039990",
        600: "#038C82",
        700: "#037c72",
        800: "#046c63",
        900: "#045046",
        950: "#03332f",
      },
      secondary: {
        50: "#f8ffe7",
        100: "#edffc3",
        200: "#deff99",
        300: "#d1fd6c",
        400: "#c5fb43",
        500: "#c1fd05",
        600: "#b4e900",
        700: "#a6d200",
        800: "#99ba00",
        900: "#839200",
        950: "#5f6d00",
      },
      colorScheme: {
        light: {
          primary: {
            color: "{primary.600}",
          },
        },
      },
    },
  },
}

export default volkswagenTheme
