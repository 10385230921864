import { z } from "zod"
import { CurrencyCodes } from "@/commons/typings/currencies"
import { CountryCodeSchema } from "@/commons/utils/countries"
import { MexicoProvinces } from "@/commons/utils/provinces"
import { zodUniqueArray } from "./../../commons/utils/zod"
import { getVintageKey } from "./../../management/composables/vintages"
import { VintageOfftakeSchema, VintageSchema } from "./vintage"

export const CarbonProjectTypeSchema = z.enum([
  "AFFORESTATION_REFORESTATION_REVEGETATION",
  "BIOCHAR",
  "ENHANCED_ROCK_WEATHERING",
  "LAND_RESTORATION",
  "AGROFORESTRY",
  "IMPROVED_FOREST_MANAGEMENT",
  "IMPROVED_AGRICULTURAL_LAND_MANAGEMENT",
  "CLEAN_ENERGY",
  "WASTE_MANAGEMENT",
  "SUSTAINABLE_LIVESTOCK",
  "UNKNOWN",
])
export type CarbonProjectTypeSchemaType = z.infer<typeof CarbonProjectTypeSchema>

export const CarbonProjectStatusSchema = z.enum([
  "CREDIT_SALE",
  "OFFTAKE_AGREEMENT",
  "SPONSORING",
  "FINANCING",
  "MANAGED",
])
export type CarbonProjectStatusSchemaType = z.infer<typeof CarbonProjectStatusSchema>

export const CarbonProjectSortPrioritySchema = z.enum(["TOP", "MIDDLE", "BOTTOM"])
export type CarbonProjectSortPrioritySchemaType = z.infer<typeof CarbonProjectSortPrioritySchema>

export const ImageSchema = z.object({
  imageId: z.string(),
  urls: z.object({
    small: z.string(),
    medium: z.string(),
    large: z.string(),
    original: z.string(),
  }),
})

export type ImageSchema = z.infer<typeof ImageSchema>

export const DocumentSchema = z.object({
  name: z.string(),
  url: z.string(),
})

export type DocumentSchema = z.infer<typeof DocumentSchema>

export const CarbonProjectBaseSchema = z.object({
  id: z.number().optional(),
  name: z.string().min(3),
  description: z.string().min(3),
  registry: z.object({
    id: z.number().nullable(),
    name: z.string(),
  }),
  availableCredits: z.number().nullable(), // Only getter, not used in forms
  projectDeveloper: z.object({
    id: z.number(),
    name: z.string(),
  }),
  countries: z
    .array(CountryCodeSchema)
    .min(1, { message: "At least one country must be selected" }),
  province: MexicoProvinces.optional(),
  projectType: CarbonProjectTypeSchema,
  sustainableDevelopmentGoals: z.array(z.number()), // change to only accept valid SDG codes
  auditor: z.object({
    id: z.number().nullish(),
    name: z.string().optional(),
  }),
  images: z.array(ImageSchema).min(1, { message: "At least one image must be selected" }),
  externalId: z.string().optional(),
  governmentId: z.string().optional(),
  documents: z.array(DocumentSchema),
  hidden: z.boolean(),
  changes: z
    .array(
      z.object({
        id: z.number(),
        change: z.string(),
        date: z.string(),
      }),
    )
    .optional(),
  embedUrl: z.string().optional(),
  youtubeVideos: z.array(z.object({ videoId: z.string() })),
  sortPriority: CarbonProjectSortPrioritySchema.optional(),
  version: z.number().optional(),
  groups: z
    .array(
      z.object({
        id: z.number(),
        name: z.string(),
      }),
    )
    .min(1, { message: "At least one group must be selected" }),
})

export const CarbonProjectCreditSaleSchema = CarbonProjectBaseSchema.extend({
  status: z.literal(CarbonProjectStatusSchema.Enum.CREDIT_SALE),
  vintages: zodUniqueArray(
    (vintage) => getVintageKey(vintage),
    z
      .array(VintageSchema)
      .min(1, { message: "At least one vintage should be created for Credit Sale project" }),
  ),
})

export type CarbonProjectCreditSaleSchema = z.infer<typeof CarbonProjectCreditSaleSchema>

export const isCreditSaleSchema = (schema: any): schema is CarbonProjectCreditSaleSchema =>
  schema.status === CarbonProjectStatusSchema.Enum.CREDIT_SALE

// ------------ SPONSORING ------------

export const CarbonProjectSponsoringSchema = CarbonProjectBaseSchema.extend({
  status: z.literal(CarbonProjectStatusSchema.Enum.SPONSORING),
  sponsoringDetails: z.object({
    expectedImpact: z.number(),
    sponsoringOpportunity: z.object({
      from: z.number(),
      to: z.number(),
    }),
    currency: CurrencyCodes,
  }),
  vintages: zodUniqueArray((vintage) => getVintageKey(vintage), z.array(VintageSchema)),
})

export type CarbonProjectSponsoringSchema = z.infer<typeof CarbonProjectSponsoringSchema>

export const isSponsoringSchema = (schema: any): schema is CarbonProjectSponsoringSchema =>
  schema.status === CarbonProjectStatusSchema.Enum.SPONSORING

// ------------ FINANCING ------------

export const CarbonProjectFinancingSchema = CarbonProjectBaseSchema.extend({
  status: z.literal(CarbonProjectStatusSchema.Enum.FINANCING),
  financingDetails: z.object({
    expectedCredits: z.number(),
    financingOpportunity: z.object({
      from: z.number(),
      to: z.number(),
    }),
    averagePricePerCredit: z.number(),
    creditingPeriod: z.array(z.number()),
    methodology: z.string(),
    currency: CurrencyCodes,
  }),
  vintages: zodUniqueArray((vintage) => getVintageKey(vintage), z.array(VintageSchema)),
})

export type CarbonProjectFinancingSchema = z.infer<typeof CarbonProjectFinancingSchema>

export const isFinancingSchema = (schema: any): schema is CarbonProjectFinancingSchema =>
  schema.status === CarbonProjectStatusSchema.Enum.FINANCING

// ------------  OFFTAKE ------------

export const CarbonProjectOfftakeSchema = CarbonProjectBaseSchema.extend({
  status: z.literal(CarbonProjectStatusSchema.Enum.OFFTAKE_AGREEMENT),
  offtakeAgreementDetails: z.object({
    vintages: z.array(VintageOfftakeSchema).min(1, { message: "At least one vintage is required" }),
    methodology: z.string().min(1),
  }),
})

export type CarbonProjectOfftakeSchema = z.infer<typeof CarbonProjectOfftakeSchema>

export const isOfftakeSchema = (schema: any): schema is CarbonProjectOfftakeSchema =>
  schema.status === CarbonProjectStatusSchema.Enum.OFFTAKE_AGREEMENT

// ------------  MANAGED ------------

export const isManagedSchema = (schema: any): schema is CarbonProjectOfftakeSchema =>
  schema.status === CarbonProjectStatusSchema.Enum.MANAGED

// ------------ CARBON PROJECT ------------

export const CarbonProjectSchema = z.discriminatedUnion("status", [
  CarbonProjectCreditSaleSchema,
  CarbonProjectSponsoringSchema,
  CarbonProjectFinancingSchema,
  CarbonProjectOfftakeSchema,
])

export type CarbonProjectSchema = z.infer<typeof CarbonProjectSchema>

export type CarbonProjectListFilters = {
  price: [number, number]
  year: [number, number]
  countries: string[]
  projectType: string[]
  registry: string[]
  impactType: string[]
  claim: string[]
}
