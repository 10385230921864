import type { RouteParams } from "vue-router"

export const coerceParams = (route: { params: RouteParams }) => {
  const params = route.params
  const result: Record<string, number | string | string[]> = {}

  for (const key in params) {
    const value = params[key]
    if (value === undefined) continue
    if (typeof value === "string") {
      result[key] = isNaN(+value) ? value : +value
      continue
    }

    result[key] = value
  }

  return result
}
