import axios from "axios"
import { useAuth } from "@/auth/composables/use-auth"
import { useTokens } from "@/auth/composables/use-tokens"
import { paramsSerializer } from "./axios-params-serializer"

const { getAuthToken, getRefreshToken, isTokenValid, refreshTokens } = useTokens()

export const http = axios.create({
  baseURL: import.meta.env.VITE_API_URL,
  paramsSerializer,
})

http.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error?.response?.status === 401) {
      const { logout } = useAuth()
      logout()
    }

    return Promise.reject(error)
  },
)

http.interceptors.request.use((config) => {
  // @ts-ignore hasOwn is already supported, weird TS bug
  if (Object.hasOwn(config.headers, "Authorization")) {
    return config
  }

  const authorizationToken = getAuthToken()
  if (isTokenValid(authorizationToken)) {
    config.headers.Authorization = `Bearer ${authorizationToken}`

    return config
  }

  const refreshToken = getRefreshToken()
  if (isTokenValid(refreshToken)) {
    config.headers.Authorization = `Bearer ${refreshToken}`
    refreshTokens()
  }

  return config
})
