import type { VintageImpactTypeSchemaType } from "@/marketplace/typings/vintage"

export const getVintageKey = (
  vintage:
    | {
        year: number
        impactType: VintageImpactTypeSchemaType
      }
    | {
        yearFrom: number
        yearTo: number
        impactType: VintageImpactTypeSchemaType
      },
) => {
  if ("yearFrom" in vintage && "yearTo" in vintage)
    return `${vintage.yearFrom}-${vintage.yearTo}-${vintage.impactType}`
  return `${vintage.year}-${vintage.impactType}`
}
