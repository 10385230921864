import type { RouteRecordRaw } from "vue-router"
import { coerceParams } from "@/commons/utils/router"

export const MarketplaceRoutesDefinitions = {
  PROJECT_DETAILS: { name: "marketplace/project-details" },
  PROJECT_LIST_ROOT: { name: "marketplace/project-list" },
  PROJECT_LIST_CREDIT_SALE: { name: "marketplace/project-list/spot" },
  PROJECT_LIST_OFFTAKE: { name: "marketplace/project-list/offtake-agreements" },
  PROJECT_LIST_FINANCING: { name: "marketplace/project-list/financing" },
  PROJECT_LIST_SPONSORING: { name: "marketplace/project-list/sponsoring" },
  CART: { name: "marketplace/cart" },
  CART_PAYMENT_DETAILS: { name: "marketplace/cart/company-details" },
  CART_CHECKOUT: { name: "marketplace/cart/checkout" },
} as const

export const marketplaceRoutes: RouteRecordRaw[] = [
  {
    path: "/marketplace",
    name: MarketplaceRoutesDefinitions.PROJECT_LIST_ROOT.name,
    redirect: { name: MarketplaceRoutesDefinitions.PROJECT_LIST_CREDIT_SALE.name },
    component: () => import("./views/ViewMarketplaceProjectList.vue"),
    children: [
      {
        path: "spot",
        name: MarketplaceRoutesDefinitions.PROJECT_LIST_CREDIT_SALE.name,
        component: () => import("./views/ViewMarketplaceProjectListSpot.vue"),
      },
      {
        path: "offtake",
        name: MarketplaceRoutesDefinitions.PROJECT_LIST_OFFTAKE.name,
        component: () => import("./views/ViewMarketplaceProjectListOfftake.vue"),
      },
      {
        path: "financing",
        name: MarketplaceRoutesDefinitions.PROJECT_LIST_FINANCING.name,
        component: () => import("./views/ViewMarketplaceProjectListFinancing.vue"),
      },
      {
        path: "sponsoring",
        name: MarketplaceRoutesDefinitions.PROJECT_LIST_SPONSORING.name,
        component: () => import("./views/ViewMarketplaceProjectListSponsoring.vue"),
      },
    ],
  },
  {
    path: "/marketplace/:id",
    name: MarketplaceRoutesDefinitions.PROJECT_DETAILS.name,
    component: () => import("./views/ViewMarketplaceProjectDetails.vue"),
    props: coerceParams,
  },
  {
    path: "/cart",
    component: () => import("./views/ViewMarketplaceCart.vue"),
    meta: {
      requirePermissions: ["PURCHASE"],
    },
    children: [
      {
        path: "",
        name: MarketplaceRoutesDefinitions.CART.name,
        component: () => import("./views/ViewMarketplaceCartOverview.vue"),
      },
      {
        path: "payment-details",
        name: MarketplaceRoutesDefinitions.CART_PAYMENT_DETAILS.name,
        component: () => import("./views/ViewMarketplaceCartInvoiceDetails.vue"),
      },
      {
        path: "checkout",
        name: MarketplaceRoutesDefinitions.CART_CHECKOUT.name,
        component: () => import("./views/ViewMarketplaceCartCheckout.vue"),
      },
    ],
  },
]
