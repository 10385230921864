import { z } from "zod"
import { CountryCodeSchema } from "@/commons/utils/countries"

export const OrganizationAddressSchema = z.object({
  street: z.string().min(3),
  postalCode: z.string().min(3),
  province: z.string().min(3),
  city: z.string().min(3),
  countryCode: CountryCodeSchema,
})

export type OrganizationAddressSchemaType = z.infer<typeof OrganizationAddressSchema>

export const OrganizationSchema = z.object({
  id: z.number().optional(),
  name: z.string().min(3),
  proposedName: z.string().optional(),
  address: OrganizationAddressSchema,
  invoiceEmail: z.string().email(),
  vatNumber: z.string().min(3),
})

export type OrganizationSchemaType = z.infer<typeof OrganizationSchema>

export const CheckoutSchema = OrganizationSchema.extend({
  version: z.number(),
})

export type CheckoutSchemaType = z.infer<typeof CheckoutSchema>
