import type { LoginResponseTokens, LoginSchema } from "@/auth/typings/login"
import type { PasswordReminderSchema, PasswordResetSchema } from "@/auth/typings/password-reminder"
import type { RegistrationSchema } from "@/auth/typings/registration"
import { auth } from "@/auth/utils/auth-axios"
import type { InvitationAcceptSchemaType } from "../typings/invitation"

export const callRefreshTokens = async (refreshToken: string): Promise<LoginResponseTokens> =>
  auth.post("/refresh", { refreshToken }).then((response) => response.data)

export const callRegister = async (
  data: RegistrationSchema,
  params: Record<string, any> = {},
): Promise<void> =>
  auth.post("/register", data, { params: { ...params } }).then((response) => response.data)

export const callRegisterVerify = async (token: string): Promise<void> =>
  auth.post("/verify", { token }).then((response) => response.data)

export const callLogin = async (data: LoginSchema): Promise<LoginResponseTokens> =>
  auth.post("/login", data).then((response) => response.data)

export const callPasswordReminder = async (data: PasswordReminderSchema): Promise<void> =>
  auth.post("/password/remind", data).then((response) => response.data)

export const callPasswordReset = async (data: PasswordResetSchema): Promise<void> =>
  auth.post("/password/reset", data).then((response) => response.data)

export const callInvitationAccept = async (data: InvitationAcceptSchemaType) =>
  auth.post<void>("/invitation/accept", data)

export const callInvitationVerify = async (token: string) =>
  auth.post<void>("/invitation/verify", { token })
