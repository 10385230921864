<script setup lang="ts">
import { RouterView } from "vue-router"
import { useUserStore } from "@/organization/composables/use-user"
import TheSidebarMenu, { SIDEBAR_CLASSES } from "../components/TheSidebarMenu.vue"
import { PublicDocuments } from "../utils/legal"

const user = useUserStore()
</script>

<template>
  <template v-if="user.isLoggedIn">
    <div class="flex">
      <the-sidebar-menu />
      <router-view v-slot="{ Component }">
        <suspense timeout="0">
          <template #default>
            <div
              class="grid min-h-100vh"
              :class="SIDEBAR_CLASSES.siteContent"
              style="grid-template-rows: 1fr auto"
            >
              <transition mode="out-in">
                <component :is="Component"></component>
              </transition>
              <div class="flex text-dimmed p-4">
                <div class="mr-4">{{ new Date().getFullYear() }} © Callirius</div>
                <div class="flex gap-2">
                  <a href="https://callirius.com">Callirius.com</a>
                  <a
                    :href="PublicDocuments.PRIVACY_POLICY"
                    target="_blank"
                    rel="noopener noreferrer"
                    >Privacy policy</a
                  >
                  <a
                    :href="PublicDocuments.TERMS_OF_SERVICE"
                    target="_blank"
                    rel="noopener noreferrer"
                    >Terms of service</a
                  >
                  <a :href="PublicDocuments.SALES_TERMS" target="_blank" rel="noopener noreferrer"
                    >Sales terms</a
                  >
                </div>
              </div>
            </div>
          </template>
          <template #fallback>
            <div class="text-center">Loading...</div>
          </template>
        </suspense>
      </router-view>
    </div>
  </template>
</template>

<style lang="scss" scoped>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.1s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
