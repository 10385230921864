import type { App } from "vue"
import { createI18n } from "vue-i18n"
import { en } from "@/commons/i18n/en"
import { scheduleSentryAction } from "./sentry"

export const i18n = createI18n<[typeof en], "en">({
  legacy: false,
  locale: "en",
  fallbackLocale: "en",
  messages: {
    en,
  },
  missing: (locale, key) => {
    console.warn(`Missing translation for ${key} in ${locale}`)
    scheduleSentryAction((instance) => {
      instance.captureEvent({
        message: `Missing translation for ${key} in ${locale}`,
        level: "warning",
      })
    })
    return ""
  },
})

export const i18nPlugin = (app: App) => {
  app.use(i18n)
}
