import type { Component } from "vue"
import { createRouter, createWebHistory } from "vue-router"
import { AuthRoutes, authRoutes } from "@/auth/routes"
import { CommonRoutesDefinitions, commonRoutes } from "@/commons/routes"
import { dashboardRoutes } from "@/dashboard/routes"
import { faqRoutes } from "@/faq/routes"
import { managementRoutes } from "@/management/routes"
import { marketplaceRoutes } from "@/marketplace/routes"
import { useUserStore } from "@/organization/composables/use-user"
import { useAuth } from "./auth/composables/use-auth"
import { organizationRoutes } from "./organization/routes"
import type { UserPermission } from "./organization/typings/user"

declare module "vue-router" {
  interface RouteMeta {
    layout?: Component
    isUnauthorized?: boolean
    requirePermissions?: UserPermission[]
  }
}

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    //
    ...commonRoutes,
    ...authRoutes,
    ...marketplaceRoutes,
    ...dashboardRoutes,
    ...managementRoutes,
    ...faqRoutes,
    ...organizationRoutes,
  ],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }

    if (to.hash) {
      return {
        el: to.hash,
        behavior: "smooth",
      }
    }

    if (to.name !== from.name) {
      return { top: 0 }
    }
  },
})

const initializeBookDemoHook = () => {
  const userStore = useUserStore()
  const unregisterInitialCheck = router.beforeEach((to, from, next) => {
    // Check if target route is book demo to prevent infinite redirect loop
    if (to.name === AuthRoutes.BOOK_DEMO.name) return next()
    if (to.meta.isUnauthorized) return next()
    if (userStore.isLoggedIn && userStore.hasRole("Awaiting Approval")) {
      return next(AuthRoutes.BOOK_DEMO)
    }

    unregisterInitialCheck()
    next()
  })
}

// This is a workaround for a check if user is logged in or not on initial page load.
const initializeLoggedInCheckHook = () => {
  const unregisterInitialHook = router.beforeEach((to, from, next) => {
    unregisterInitialHook()
    const { isLoggedIn } = useAuth()

    if (to.meta.isUnauthorized) return next()
    if (!isLoggedIn.value) return next({ ...AuthRoutes.LOGIN, query: { next: to.fullPath } })

    next()
  })
}

const initializePermissionsHook = () => {
  const userStore = useUserStore()

  router.beforeEach((to, from, next) => {
    if (!to.meta.requirePermissions) return next()

    const hasAllPermissions = to.meta.requirePermissions.every((permission) =>
      userStore.hasPermission(permission),
    )

    if (hasAllPermissions) return next()

    next({
      ...CommonRoutesDefinitions.CATCH_ALL,
      params: { pathMatch: to.path.substring(1).split("/") },
      query: to.query,
      hash: to.hash,
      state: {
        title: "Permission Denied",
        message: "You don't have permission to access this page",
      },
    })
  })
}

export const initializeRouterHooks = () => {
  initializeLoggedInCheckHook()
  initializeBookDemoHook()
  initializePermissionsHook()
}

export default router
