import type { RouteRecordRaw } from "vue-router"

export const FAQRoutesDefinitions = {
  FAQ: { name: "faq/default" },
  DD_REPORTS: { name: "faq/dd-reports" },
} as const

export const faqRoutes: RouteRecordRaw[] = [
  {
    path: "/faq",
    name: FAQRoutesDefinitions.FAQ.name,
    component: () => import("./views/ViewFAQ.vue"),
  },
  {
    path: "/faq/due-diligence-reports",
    name: FAQRoutesDefinitions.DD_REPORTS.name,
    redirect: { name: FAQRoutesDefinitions.FAQ.name, query: { search: "demo report" } },
  },
]
