import { hasFeatureFlag } from "@/commons/plugins/feature-flags"
import calliriusTheme from "@/commons/themes/callirius"
import lorealTheme from "@/commons/themes/loreal"
import volkswagenTheme from "@/commons/themes/volkswagen"

export const useTheme = () => {
  const theme = hasFeatureFlag("enable_theme_vw")
    ? volkswagenTheme
    : hasFeatureFlag("enable_theme_loreal")
      ? lorealTheme
      : calliriusTheme

  const injectStyles = () => {
    const root = document.documentElement
    for (const [key, value] of Object.entries(theme.cssVariables)) {
      root.style.setProperty(key, value)
    }

    theme.importCustomStyles?.()
  }

  const getLogo = () => theme.logo

  const getHeaderBackground = () => theme.headerBackground

  const getPrimevueTheme = () => theme.primevueTheme

  const getChartColors = () => theme.chartColors

  return {
    injectStyles,
    getLogo,
    getHeaderBackground,
    getPrimevueTheme,
    getChartColors,
  }
}
