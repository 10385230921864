import { z } from "zod"

export const CurrencyCodes = z.enum(["EUR", "USD", "MXN"])

export type CurrencyCodes = z.infer<typeof CurrencyCodes>

export type Currency = {
  symbol: string
}

export const currencies: Record<CurrencyCodes, Currency> = {
  EUR: {
    symbol: "€",
  },
  USD: {
    symbol: "$",
  },
  MXN: {
    symbol: "MX$",
  },
} as const
