import type { Theme } from "@/commons/typings/theme"
import headerBackground from "./img/header-background.jpg"
import logo from "./img/logo.svg"

const calliriusTheme: Theme = {
  logo,
  headerBackground,
  chartColors: [
    "#002f44",
    "#1ebdef",
    "#52e8cd",
    "#00fdff",
    "#37d1df",
    "#ecf9fe",
    "#738a94",
    "#f0fcfa",
    "#2a4756",
    "#b0d8e5",
    "#e8e8e8",
  ],
  importCustomStyles: () => import("./styles/index.css"),
  cssVariables: {
    "--color-primary": "#1ebdef",
    "--color-secondary": "#52e8cd",
    "--color-tertiary": "#00fdff",
    "--color-dark": "#002f44",
    "--color-on-primary": "#fff",
    "--color-on-secondary": "#000",
    "--color-on-tertiary": "#000",
    "--color-on-dark": "#fff",
    "--font-family": '"Poppins", sans-serif',
    "--font-family-heading": '"Poppins", sans-serif',
    "--font-family-mono": '"Chivo Mono", monospace',
    "--header-gradient":
      "linear-gradient(90deg, color-mix(in srgb, var(--color-secondary) 85%, transparent), color-mix(in srgb, var(--color-primary) 85%, transparent))",
  },
  primevueTheme: {
    semantic: {
      // https://m2.material.io/design/color/the-color-system.html#tools-for-picking-colors
      primary: {
        50: "#def4fc",
        100: "#abe3f7",
        200: "#70d1f3",
        300: "#1ebeef",
        400: "#00b1ee",
        500: "#00a3ec",
        600: "#0095de",
        700: "#0083cb",
        800: "#0072b8",
        900: "#005297",
        950: "#003d6f",
      },
      secondary: {
        50: "#dafaf4",
        100: "#a2f1e0",
        200: "#52e8cd",
        300: "#00dbb7",
        400: "#00cea6",
        500: "#00c296",
        600: "#00b388",
        700: "#00a176",
        800: "#009067",
        900: "#007249",
        950: "#004d2b",
      },
      colorScheme: {
        light: {
          primary: {
            color: "{primary.300}",
          },
        },
      },
    },
  },
}

export default calliriusTheme
