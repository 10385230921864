import { vOnClickOutside } from "@vueuse/components"
import { createPinia } from "pinia"
import { createApp, ref } from "vue"
import type { InjectionKey, Ref } from "vue"
import { featureFlagsPlugin, initializeFeatureFlags } from "@/commons/plugins/feature-flags"
import { i18nPlugin } from "@/commons/plugins/i18n"
import { primevuePlugin } from "@/commons/plugins/primevue"
import { sentryPlugin } from "@/commons/plugins/sentry"
import { zodPlugin } from "@/commons/plugins/zod"
import App from "./App.vue"
import { initializeApp } from "./initialize"
import router from "./router"

// Import UnoCSS
import "virtual:uno.css"

export const isInitializedSymbol: InjectionKey<Ref<boolean>> = Symbol("isInitialized")
const isInitialized = ref(false)

const init = async () => {
  const app = createApp(App)

  app.provide(isInitializedSymbol, isInitialized)

  await initializeFeatureFlags()

  app.use(sentryPlugin(router))
  app.use(featureFlagsPlugin)
  app.use(primevuePlugin)
  app.use(createPinia())

  // We need to initialize the app before the Vue Router
  await initializeApp()

  app.use(router)
  app.use(i18nPlugin)
  app.use(zodPlugin)

  app.directive("click-outside", vOnClickOutside)

  await router.isReady()
  app.mount("#app")

  isInitialized.value = true
}

init()
