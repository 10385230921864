import type { RouteRecordRaw } from "vue-router"

export const DashboardRoutesDefinitions = {
  DASHBOARD: { name: "dashbaord/dashboard" },
} as const

export const dashboardRoutes: RouteRecordRaw[] = [
  {
    // Legacy portfolio route, redirect to dashboard
    path: "/portfolio",
    redirect: { name: DashboardRoutesDefinitions.DASHBOARD.name },
  },
  {
    path: "/dashboard",
    name: DashboardRoutesDefinitions.DASHBOARD.name,
    component: () => import("./views/ViewDashboard.vue"),
  },
]
