<script setup lang="ts">
import PrimeButton from "primevue/button"
import type { ButtonProps } from "primevue/button"

type ButtonSize = "small" | "medium" | "large"
type UiButtonProps = {
  style?: ButtonProps["style"]
  class?: ButtonProps["class"]
  label?: ButtonProps["label"]
  icon?: ButtonProps["icon"]
  iconPos?: ButtonProps["iconPos"]
  iconClass?: ButtonProps["iconClass"]
  badge?: ButtonProps["badge"]
  badgeClass?: ButtonProps["badgeClass"]
  badgeSeverity?: ButtonProps["badgeSeverity"]
  loading?: ButtonProps["loading"]
  loadingIcon?: ButtonProps["loadingIcon"]
  link?: ButtonProps["link"]
  severity?: ButtonProps["severity"]
  raised?: ButtonProps["raised"]
  rounded?: ButtonProps["rounded"]
  text?: ButtonProps["text"]
  outlined?: ButtonProps["outlined"]
  size?: ButtonSize
  plain?: ButtonProps["plain"]
  pt?: ButtonProps["pt"]
  ptOptions?: ButtonProps["ptOptions"]
  unstyled?: ButtonProps["unstyled"]
}

const props = withDefaults(defineProps<UiButtonProps>(), {
  style: undefined,
  class: undefined,
  label: undefined,
  icon: undefined,
  iconPos: undefined,
  iconClass: undefined,
  badge: undefined,
  badgeClass: undefined,
  badgeSeverity: undefined,
  loading: undefined,
  loadingIcon: undefined,
  link: undefined,
  severity: undefined,
  raised: false,
  rounded: false,
  text: false,
  outlined: false,
  plain: false,
  pt: undefined,
  ptOptions: undefined,
  unstyled: false,
  size: "small",
})

const toSize = (size: ButtonSize) => {
  // PrimeVue's default size is medium, so we have to pass undefined
  if (size === "medium") return undefined
  return size
}
</script>

<template>
  <prime-button
    class="ui-button decoration-none"
    v-bind="{ ...$attrs, ...props }"
    :size="toSize(props.size)"
    :pt="{
      label: {
        class: 'font-normal',
      },
    }"
  >
    <slot />
  </prime-button>
</template>
