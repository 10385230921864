import { ref, readonly } from "vue"
import { identifyUserForFeatureFlags } from "@/commons/plugins/feature-flags"
import { CommonRoutesDefinitions } from "@/commons/routes"
import { useUserStore } from "@/organization/composables/use-user"
import type { UserBaseSchema } from "@/organization/typings/user"
import router from "@/router"
import { callLogin } from "../api/auth-api"
import { AuthRoutes } from "../routes"
import type { LoginSchema } from "../typings/login"
import { decodeJwtToken } from "../utils/token"
import { useTokens } from "./use-tokens"

export const useAuth = () => {
  const { clearTokens, setTokens, getAuthToken } = useTokens()

  const isLoggedIn = ref(!!getAuthToken())

  const initializeUser = () => {
    try {
      const userStore = useUserStore()
      const token = getAuthToken()

      const payload = decodeJwtToken<{ user: UserBaseSchema }>(token)
      userStore.setUser(payload.user)
      identifyUserForFeatureFlags(payload.user.username)
    } catch (error) {
      logout()
    }
  }

  const login = async (data: LoginSchema) => {
    try {
      const tokens = await callLogin(data)

      setTokens(tokens)
      initializeUser()

      const redirectTarget =
        (router.currentRoute.value.query.next as string) || CommonRoutesDefinitions.DEFAULT

      router.push(redirectTarget)
    } catch (error) {
      console.error(error)

      throw error
    }
  }

  const logout = () => {
    const loginRoute = router.resolve(AuthRoutes.LOGIN)
    clearTokens()
    location.href = loginRoute.href
  }

  return {
    initializeUser,
    login,
    logout,
    isLoggedIn: readonly(isLoggedIn),
  }
}
