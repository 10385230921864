<script setup lang="ts">
import PrimeMenu from "primevue/menu"
import { ref } from "vue"
import { useRouter } from "vue-router"
import { useAuth } from "@/auth/composables/use-auth"
import { useUserStore } from "@/organization/composables/use-user"
import { OrganizationRoutesDefinitions } from "@/organization/routes"
import UiAvatar from "@/ui/atoms/UiAvatar.vue"
import UiTransitionFade from "@/ui/transitions/UiTransitionFade.vue"
import SidebarMenuItemBase from "./SidebarMenuItemBase.vue"

const emits = defineEmits<{
  (e: "click:item"): void
}>()

const { logout } = useAuth()
const router = useRouter()
const user = useUserStore()

const menuVisible = ref(false)

const items = ref([
  {
    label: "Organization",
    icon: "i-mdi-domain",
    command: async () => {
      await router.push(OrganizationRoutesDefinitions.ROOT)
      menuVisible.value = false
      emits("click:item")
    },
  },
  {
    label: "",
    items: [
      {
        label: "Logout",
        icon: "i-mdi-logout",
        command: () => {
          logout()
          menuVisible.value = false
          emits("click:item")
        },
      },
    ],
  },
])

const onClickOutside = () => {
  menuVisible.value = false
}
</script>

<template>
  <div class="relative">
    <sidebar-menu-item-base
      v-bind="{ ...$attrs, ...$props }"
      tag="button"
      aria-haspopup="true"
      aria-controls="user-menu"
      @click="menuVisible = !menuVisible"
    >
      <ui-avatar size="large" :user="user" />
    </sidebar-menu-item-base>
    <ui-transition-fade>
      <div v-show="menuVisible" v-click-outside="onClickOutside">
        <prime-menu id="user-menu" :model="items" class="absolute bottom-0 left-[calc(100%+5px)]" />
      </div>
    </ui-transition-fade>
  </div>
</template>

<style scoped lang="scss"></style>
