import type { Theme } from "@/commons/typings/theme"
import headerBackground from "./img/header-background.jpg"
import logo from "./img/logo.svg"

const lorealParisTheme: Theme = {
  logo,
  headerBackground,
  chartColors: [
    "#006ef9",
    "#b6115a",
    "#4d4084",
    "#009c65",
    "#f09100",
    "#3ae2d8",
    "#b487b8",
    "#00d88d",
    "#f7c7733",
    "#53042a",
    "#aa2800",
  ],
  importCustomStyles: () => import("./styles/index.css"),
  cssVariables: {
    "--color-primary": "#3ae2d8",
    "--color-secondary": "#00d88d",
    "--color-tertiary": "#00fdff",
    "--color-dark": "#000000",
    "--color-on-primary": "#fff",
    "--color-on-secondary": "#000",
    "--color-on-tertiary": "#000",
    "--color-on-dark": "#fff",
    "--font-family": "campton, arial, sans-serif",
    "--font-family-heading": "campton, arial, sans-serif",
    "--font-family-mono": "monospace",
    "--header-gradient":
      "linear-gradient(90deg, color-mix(in srgb, var(--color-primary) 75%, transparent), color-mix(in srgb, var(--color-secondary) 55%, transparent))",
  },
  primevueTheme: {
    semantic: {
      // https://m2.material.io/design/color/the-color-system.html#tools-for-picking-colors
      primary: {
        50: "#f5f5f5",
        100: "#e9e9e9",
        200: "#d9d9d9",
        300: "#c4c4c4",
        400: "#9d9d9d",
        500: "#7b7b7b",
        600: "#555555",
        700: "#434343b",
        800: "#262626",
        900: "#000",
        950: "#000",
      },
      secondary: {
        50: "#dafaf4",
        100: "#a2f1e0",
        200: "#52e8cd",
        300: "#00dbb7",
        400: "#00cea6",
        500: "#00c296",
        600: "#00b388",
        700: "#00a176",
        800: "#009067",
        900: "#007249",
        950: "#004d2b",
      },
      colorScheme: {
        light: {
          primary: {
            color: "{primary.900}",
          },
        },
      },
    },
  },
}

export default lorealParisTheme
