import { cloneDeep } from "lodash-es"
import { defineStore } from "pinia"
import type { Pinia } from "pinia"
import { computed, ref, watch } from "vue"
import { CurrencyCodes } from "@/commons/typings/currencies"
import {
  fetchInvoiceDetails as apiFetchInvoiceDetails,
  callSaveInvoiceDetails,
} from "@/organization/api/organization"
import {
  fetchCart as apiFetchCart,
  callCartCheckout,
  callCartAddVintage,
  callCartRemoveVintage,
  callCartAddReport,
  callCartRemoveReport,
} from "../api/cart"
import type { OrderSchemaType } from "../typings/order"
import type { OrganizationSchemaType } from "../typings/organization"
import type { CountryCodeSchemaType } from "./../../commons/utils/countries"
import type { CartSchemaType, CartItemVintageSchemaType } from "./../typings/cart"
import { OrderStatusSchema } from "./../typings/order"

const DEFAULT_CART: Readonly<CartSchemaType> = Object.freeze({
  id: 0,
  userId: 0,
  items: [],
  totalCredits: 0,
  currency: "EUR",
  vatPercentage: 0,
  version: 0,
  credits: 0,
  price: {
    net: 0,
    gross: 0,
    vatAmount: 0,
  },
  pricePreDiscount: {
    net: 0,
    gross: 0,
    vatAmount: 0,
  },
})

const DEFAULT_INVOICE_DETAILS: Readonly<OrganizationSchemaType> = Object.freeze({
  id: 0,
  name: "",
  address: {
    street: "",
    postalCode: "",
    province: "",
    city: "",
    countryCode: "" as CountryCodeSchemaType,
  },
  invoiceEmail: "",
  vatNumber: "",
})

const DEFAULT_CHECKOUT_RESULT: Readonly<OrderSchemaType> = Object.freeze({
  status: OrderStatusSchema.Enum.AWAITING_APPROVAL,
  id: 0,
  currency: CurrencyCodes.Enum.EUR,
  vatPercentage: 0,
  companyName: "",
  address: {
    street: "",
    postalCode: "",
    province: "",
    city: "",
    countryCode: "" as CountryCodeSchemaType,
  },
  invoiceEmail: "",
  invoiceName: "",
  invoiceFileId: "",
  vatNumber: "",
  createdAt: "",
  paidAt: "",
  userId: 0,
  items: [],
  invoiceNumber: "",
  transactionId: "",
  credits: 0,
  price: {
    net: 0,
    gross: 0,
    vatAmount: 0,
  },
  pricePreDiscount: {
    net: 0,
    gross: 0,
    vatAmount: 0,
  },
})

const useCartStore = defineStore("cart", () => {
  // Initialization
  const isInitialized = ref(false)

  const initialize = async () => {
    // calling fetch invoice details first,
    // after it resolves, fetchCart() is called from watch
    // calling fetchCart() here results in multiple chain cart calls
    await fetchInvoiceDetails()
    isInitialized.value = true
  }

  // Invoice details
  const invoiceDetails = ref<OrganizationSchemaType>(cloneDeep(DEFAULT_INVOICE_DETAILS))

  const fetchInvoiceDetails = async () => {
    const result = await apiFetchInvoiceDetails()

    invoiceDetails.value = {
      ...result,
      name: result.proposedName || result.name || "",
    }
  }

  const saveInvoiceDetails = async () => {
    await callSaveInvoiceDetails(invoiceDetails.value)
    await fetchInvoiceDetails()
  }

  // Checkout
  const checkoutResult = ref<OrderSchemaType>(cloneDeep(DEFAULT_CHECKOUT_RESULT))

  const checkout = async () => {
    const result = await callCartCheckout({
      ...invoiceDetails.value,
      version: cart.value.version,
    })
    checkoutResult.value = result
    cart.value = cloneDeep(DEFAULT_CART)
  }

  const clearCheckout = () => {
    checkoutResult.value = cloneDeep(DEFAULT_CHECKOUT_RESULT)
  }

  const isCheckout = computed(() => checkoutResult.value.items.length > 0)

  // Cart
  const cart = ref<CartSchemaType>(cloneDeep(DEFAULT_CART))
  const fetchCart = async () => {
    const countryCode = invoiceDetails.value.address.countryCode
    const result = await apiFetchCart(countryCode)
    cart.value = result
  }

  const isEmpty = computed(() => cart.value.items.length === 0)

  const vintagesCredits = computed<Record<number, number>>(() => {
    const vintageQuantityEntries = cart.value.items
      .filter((item): item is CartItemVintageSchemaType => item.productType === "VINTAGE_CREDITS")
      .map(({ product, quantity }) => [product.id, quantity])

    return Object.fromEntries(vintageQuantityEntries)
  })

  const addVintageToCart = async (vintageId: number, credits: number) => {
    const countryCode = invoiceDetails.value.address.countryCode
    const result =
      credits > 0
        ? await callCartAddVintage({ vintageId, credits }, countryCode)
        : await callCartRemoveVintage({ vintageId }, countryCode)
    cart.value = result
  }

  const removeVintageFromCart = async (vintageId: number) => addVintageToCart(vintageId, 0)

  const addReportToCart = async (reportId: number) => {
    const countryCode = invoiceDetails.value.address.countryCode
    const result = await callCartAddReport({ reportId }, countryCode)
    cart.value = result
  }

  const removeReportFromCart = async (reportId: number) => {
    const countryCode = invoiceDetails.value.address.countryCode
    const result = await callCartRemoveReport({ reportId }, countryCode)
    cart.value = result
  }

  const hasReportInCart = (reportId: number) =>
    cart.value.items.some(
      (item) => item.productType === "DUE_DILIGENCE_REPORT" && item.product.reportId === reportId,
    )

  watch(
    () => invoiceDetails.value.address.countryCode,
    async () => {
      fetchCart()
    },
  )

  return {
    // Initialization
    isInitialized,
    initialize,
    // Invoice
    invoiceDetails,
    saveInvoiceDetails,
    // Checkout
    checkoutResult,
    checkout,
    clearCheckout,
    isCheckout,
    // Cart
    cart,
    isEmpty,
    vintagesCredits,
    addVintageToCart,
    removeVintageFromCart,
    addReportToCart,
    removeReportFromCart,
    hasReportInCart,
  }
})

export const useCart = (piniaInstance?: Pinia) => {
  const store = useCartStore(piniaInstance)
  if (!store.isInitialized) {
    store.initialize()
  }

  return store
}
