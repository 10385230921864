import type { RouteRecordRaw } from "vue-router"
import { coerceParams } from "@/commons/utils/router"

export const ManagementRoutesDefinitions = {
  MANAGEMENT: { name: "management" },
  MANAGEMENT_PROJECTS: { name: "management/projects" },
  MANAGEMENT_PROJECTS_UPDATE: { name: "management/projects/update" },
  MANAGEMENT_PROJECT_GROUPS: { name: "management/project-groups" },
  MANAGEMENT_PROJECT_GROUPS_UPDATE: { name: "management/project-groups/update" },
  MANAGEMENT_DD_REPORTS: { name: "management/reports" },
  MANAGEMENT_DD_REPORTS_UPDATE: { name: "management/reports/update" },
  MANAGEMENT_USERS: { name: "management/users" },
  MANAGEMENT_ORDERS: { name: "management/orders" },
  MANAGEMENT_RETIREMENTS: { name: "management/retirements" },
  MANAGEMENT_AUDITORS: { name: "management/auditors" },
  MANAGEMENT_DEVELOPERS: { name: "management/developers" },
  MANAGEMENT_REGISTRIES: { name: "management/registries" },
  MANAGEMENT_PARTNERS: { name: "management/partners" },
  MANAGEMENT_ORGANIZATIONS: { name: "management/organizations" },
  MANAGEMENT_ORGANIZATION_NAME_CHANGE_REQUESTS: {
    name: "management/organizations/name-change-requests",
  },
  MANAGEMENT_ORGANIZATION_DETAILS: { name: "management/organizations/details" },
  MANAGEMENT_ORGANIZATION_CUSTOM_MARGINS: { name: "management/organizations/custom-margins" },
  MANAGEMENT_ORGANIZATION_PORTFOLIO: { name: "management/organizations/portfolio" },
  MANAGEMENT_ORGANIZATION_DECARBONIZATION: { name: "management/organizations/decarbonization" },
  MANAGEMENT_ORGANIZATION_USERS: { name: "management/organizations/users" },
} as const

export const managementRoutes: RouteRecordRaw[] = [
  {
    path: "/management",
    name: ManagementRoutesDefinitions.MANAGEMENT.name,
    component: () => import("./views/ViewManagement.vue"),
    redirect: ManagementRoutesDefinitions.MANAGEMENT_PROJECTS,
    meta: {
      requirePermissions: ["ADMINISTRATION"],
    },
    children: [
      {
        path: "projects",
        name: ManagementRoutesDefinitions.MANAGEMENT_PROJECTS.name,
        components: {
          default: () => import("./views/ViewManagementProjects.vue"),
          submenu: () => import("./views/_ManagementProjectsSubmenu.vue"),
        },
      },
      {
        path: "projects/:id",
        name: ManagementRoutesDefinitions.MANAGEMENT_PROJECTS_UPDATE.name,
        component: () => import("./views/ViewManagementProjectUpdate.vue"),
        props: coerceParams,
      },
      {
        path: "project-groups",
        name: ManagementRoutesDefinitions.MANAGEMENT_PROJECT_GROUPS.name,
        components: {
          default: () => import("./views/ViewManagementProjectGroups.vue"),
          submenu: () => import("./views/_ManagementProjectsSubmenu.vue"),
        },
      },
      {
        path: "project-groups/:id",
        name: ManagementRoutesDefinitions.MANAGEMENT_PROJECT_GROUPS_UPDATE.name,
        component: () => import("./views/ViewManagementProjectGroupUpdate.vue"),
        props: coerceParams,
      },
      {
        path: "reports",
        name: ManagementRoutesDefinitions.MANAGEMENT_DD_REPORTS.name,
        component: () => import("./views/ViewManagementDdReports.vue"),
      },
      {
        path: "reports/:id",
        name: ManagementRoutesDefinitions.MANAGEMENT_DD_REPORTS_UPDATE.name,
        component: () => import("./views/ViewManagementDdReportUpdate.vue"),
        props: coerceParams,
      },
      {
        path: "users",
        name: ManagementRoutesDefinitions.MANAGEMENT_USERS.name,
        component: () => import("./views/ViewManagementUsers.vue"),
      },
      {
        path: "orders",
        name: ManagementRoutesDefinitions.MANAGEMENT_ORDERS.name,
        component: () => import("./views/ViewManagementOrders.vue"),
      },
      {
        path: "retirements",
        name: ManagementRoutesDefinitions.MANAGEMENT_RETIREMENTS.name,
        component: () => import("./views/ViewManagementRetirements.vue"),
      },
      {
        path: "auditors",
        name: ManagementRoutesDefinitions.MANAGEMENT_AUDITORS.name,
        component: () => import("./views/ViewManagementAuditors.vue"),
      },
      {
        path: "developers",
        name: ManagementRoutesDefinitions.MANAGEMENT_DEVELOPERS.name,
        component: () => import("./views/ViewManagementDevelopers.vue"),
      },
      {
        path: "registries",
        name: ManagementRoutesDefinitions.MANAGEMENT_REGISTRIES.name,
        component: () => import("./views/ViewManagementRegistries.vue"),
      },
      {
        path: "partners",
        name: ManagementRoutesDefinitions.MANAGEMENT_PARTNERS.name,
        component: () => import("./views/ViewManagementPartners.vue"),
      },
      {
        path: "organizations",
        name: ManagementRoutesDefinitions.MANAGEMENT_ORGANIZATIONS.name,
        components: {
          default: () => import("./views/ViewManagementOrganizations.vue"),
          submenu: () => import("./views/_ManagementOrganizationsSubmenu.vue"),
        },
      },
      {
        path: "organizations/name-change-requests",
        name: ManagementRoutesDefinitions.MANAGEMENT_ORGANIZATION_NAME_CHANGE_REQUESTS.name,
        components: {
          default: () => import("./views/ViewManagementOrganizationNameChangeRequests.vue"),
          submenu: () => import("./views/_ManagementOrganizationsSubmenu.vue"),
        },
      },
      {
        path: "organizations/:id",
        component: () => import("./views/ViewManagementOrganizationRoot.vue"),
        props: coerceParams,
        redirect: { name: ManagementRoutesDefinitions.MANAGEMENT_ORGANIZATION_DETAILS.name },
        children: [
          {
            path: "details",
            name: ManagementRoutesDefinitions.MANAGEMENT_ORGANIZATION_DETAILS.name,
            component: () => import("./views/ViewManagementOrganizationDetails.vue"),
            props: coerceParams,
          },
          {
            path: "custom-margins",
            name: ManagementRoutesDefinitions.MANAGEMENT_ORGANIZATION_CUSTOM_MARGINS.name,
            component: () => import("./views/ViewManagementOrganizationCustomMargins.vue"),
            props: coerceParams,
          },
          {
            path: "portfolio",
            name: ManagementRoutesDefinitions.MANAGEMENT_ORGANIZATION_PORTFOLIO.name,
            component: () => import("./views/ViewManagementOrganizationPortfolio.vue"),
            props: coerceParams,
          },
          {
            path: "decarbonization",
            name: ManagementRoutesDefinitions.MANAGEMENT_ORGANIZATION_DECARBONIZATION.name,
            component: () => import("./views/ViewManagementOrganizationDecarbonization.vue"),
            props: coerceParams,
          },
          {
            path: "users",
            name: ManagementRoutesDefinitions.MANAGEMENT_ORGANIZATION_USERS.name,
            component: () => import("./views/ViewManagementOrganizationUsers.vue"),
            props: coerceParams,
          },
        ],
      },
    ],
  },
]
