const hash = (str: string) => {
  let hash = 5381
  for (let i = 0; i < str.length; i++) {
    hash = (hash * 33) ^ (str.charCodeAt(i) * 971)
  }
  return Math.abs(hash)
}

export class ColorHash {
  L: number[]
  S: number[]
  hueRanges: { max: number; min: number }[]
  hash: (str: string) => number

  constructor(
    options: {
      lightness?: number | number[]
      saturation?: number | number[]
      hue?: number | { max: number; min: number } | { max: number; min: number }[]
      hash?: string | ((str: string) => number)
    } = {},
  ) {
    const [L, S] = [options.lightness, options.saturation].map(function (param) {
      param = param !== undefined ? param : [0.35, 0.5, 0.65] // note that 3 is a prime
      return Array.isArray(param) ? param.concat() : [param]
    })

    this.L = L
    this.S = S

    if (typeof options.hue === "number") {
      options.hue = { min: options.hue, max: options.hue }
    }
    if (typeof options.hue === "object" && !Array.isArray(options.hue)) {
      options.hue = [options.hue]
    }
    if (typeof options.hue === "undefined") {
      options.hue = []
    }
    this.hueRanges = options.hue.map(function (range) {
      return {
        min: typeof range.min === "undefined" ? 0 : range.min,
        max: typeof range.max === "undefined" ? 360 : range.max,
      }
    })

    this.hash = hash // Default hash function
    if (typeof options.hash === "function") {
      this.hash = options.hash
    }
  }

  /**
   * Returns the hash in [h, s, l].
   * Note that H ∈ [0, 360); S ∈ [0, 1]; L ∈ [0, 1];
   *
   * @param {String} str string to hash
   * @returns {Array} [h, s, l]
   */
  hsl(str: string): [number, number, number] {
    let H
    let hash = this.hash(str)
    const hueResolution = 727 // note that 727 is a prime

    if (this.hueRanges.length) {
      const range = this.hueRanges[hash % this.hueRanges.length]
      H =
        (((hash / this.hueRanges.length) % hueResolution) * (range.max - range.min)) /
          hueResolution +
        range.min
    } else {
      H = hash % 359 // note that 359 is a prime
    }
    hash = Math.ceil(hash / 360)
    const S = this.S[hash % this.S.length]
    hash = Math.ceil(hash / this.S.length)
    const L = this.L[hash % this.L.length]

    return [H, S, L]
  }

  hslCSS(str: string): string {
    const hsl = this.hsl(str)
    return `hsl(${hsl[0]}, ${hsl[1] * 100}%, ${hsl[2] * 100}%)`
  }
}
