<script setup lang="ts">
import PrimeBadge from "primevue/badge"
import type { BadgeProps, BadgeSlots } from "primevue/badge"
import { computed } from "vue"

const props = withDefaults(
  defineProps<
    BadgeProps & {
      blink?: boolean
    }
  >(),
  {
    size: "small",
    blink: false,
  },
)

const slots = defineSlots<BadgeSlots>()

const isDot = computed(() => !props.value && !slots.default)
</script>

<template>
  <prime-badge
    v-bind="{ ...$attrs, ...props }"
    :class="{ blink: props.blink, dot: isDot }"
    class="min-w-4 h-4 lh-1rem text-10px"
  >
    <template v-for="(_, name) in slots as unknown" #[name]="slotData">
      <slot :name="name" v-bind="(slotData as unknown) || {}" />
    </template>
  </prime-badge>
</template>

<style lang="scss" scoped>
.blink {
  animation: blink 10s;
}

.dot {
  width: 0.5rem;
  min-width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  padding: 0;
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  2% {
    opacity: 0.1;
  }
  4% {
    opacity: 1;
  }
  6% {
    opacity: 0.1;
  }
  8% {
    opacity: 1;
  }
}
</style>
