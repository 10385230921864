import flagsmith from "flagsmith-es"
import flagsmithJSON from "virtual:flagsmith-config"
import type { App, InjectionKey } from "vue"

export const hasFeatureFlagSymbol: InjectionKey<(featureFlag: FeatureFlags) => boolean> =
  Symbol("hasFeatureFlag")

export const initializeFeatureFlags = async () => {
  await flagsmith.init({
    environmentID: import.meta.env.VITE_FLAGSMITH_ENVIRONMENT_ID,
    cacheFlags: true,
    state: flagsmithJSON,
    preventFetch: import.meta.env.DEV,
  })
}

export const hasFeatureFlag = (featureFlag: FeatureFlags) => {
  return flagsmith.hasFeature(featureFlag)
}

export const identifyUserForFeatureFlags = (userId: string) => {
  if (import.meta.env.DEV) return

  flagsmith.identify(userId)
}

export const featureFlagsPlugin = (app: App) => {
  app.provide(hasFeatureFlagSymbol, hasFeatureFlag)
}
