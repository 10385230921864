import { z } from "zod"
import { ModifierSchema } from "@/management/typings/modifier"

export const PriceSchema = z.object({
  base: z.number().min(0.01, { message: "Price must be greater than 0" }),
  modifier: ModifierSchema.optional(),
  total: z.number(),
})

export type PriceSchemaType = z.infer<typeof PriceSchema>
