import { z } from "zod"

export const MexicoProvinces = z.enum([
  "AGUASCALIENTES",
  "BAJA_CALIFORNIA",
  "BAJA_CALIFORNIA_SUR",
  "CAMPECHE",
  "CHIAPAS",
  "CHIHUAHUA",
  "COAHUILA",
  "COLIMA",
  "DURANGO",
  "GUANAJUATO",
  "GUERRERO",
  "HIDALGO",
  "JALISCO",
  "MEXICO_CITY",
  "MEXICO_STATE",
  "MICHOACAN",
  "MORELOS",
  "NAYARIT",
  "NUEVO_LEON",
  "OAXACA",
  "PUEBLA",
  "QUERETARO",
  "QUINTANA_ROO",
  "SAN_LUIS_POTOSI",
  "SINALOA",
  "SONORA",
  "TABASCO",
  "TAMAULIPAS",
  "TLAXCALA",
  "VERACRUZ",
  "YUCATAN",
  "ZACATECAS",
])

export type MexicoProvincesType = z.infer<typeof MexicoProvinces>
