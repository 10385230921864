import { ref, watch } from "vue"
import type { App } from "vue"
import type { Router } from "vue-router"

type SentryInstance = typeof import("@sentry/vue")
const sentryInstance = ref<SentryInstance | null>(null)

export const scheduleSentryAction = (action: (instance: SentryInstance) => void) => {
  if (!import.meta.env.VITE_SENTRY_DSN) return
  if (sentryInstance.value) {
    action(sentryInstance.value as SentryInstance)
    return
  }

  const unwatch = watch(sentryInstance, (instance) => {
    if (instance) {
      action(instance as SentryInstance)
      unwatch()
    }
  })
}

const getEnvironment = () => {
  if (window.location.hostname === "app.callirius.com") return "prod"
  if (window.location.hostname === "test.callirius.org") return "dev"
  return "local"
}

export const sentryPlugin = (router: Router) => async (app: App) => {
  if (!import.meta.env.VITE_SENTRY_DSN) return

  const Sentry = await import("@sentry/vue")
  sentryInstance.value = Sentry

  Sentry.init({
    app,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    release: import.meta.env.VITE_RELEASE_VERSION,
    environment: getEnvironment(),
    integrations: [
      new Sentry.BrowserTracing({
        tracePropagationTargets: ["localhost", /^https:\/\/app\.callirius\.com\/api/],
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
      new Sentry.Replay({
        maskAllText: false,
        blockAllMedia: false,
        networkDetailAllowUrls: [/^https:\/\/app\.callirius\.com\/api/],
      }),
    ],
    tracesSampleRate: Number(import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE),
    replaysSessionSampleRate: Number(import.meta.env.VITE_SENTRY_REPLAYS_SAMPLE_RATE),
    replaysOnErrorSampleRate: Number(import.meta.env.VITE_SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE),
  })
}
