import { definePreset } from "@primevue/themes"
import Aura from "@primevue/themes/aura"
import merge from "lodash-es/merge"
import PrimeVue from "primevue/config"
import ConfirmationService from "primevue/confirmationservice"
import DialogService from "primevue/dialogservice"
import Ripple from "primevue/ripple"
import ToastService from "primevue/toastservice"
import TooltipDirective from "primevue/tooltip"
import type { App } from "vue"
import { useTheme } from "@/commons/composables/use-theme"

export const primevuePlugin = (app: App) => {
  const { getPrimevueTheme } = useTheme()
  const mergedStyles = merge(
    {},
    {
      primitive: {
        borderRadius: {
          // Limit the border radius to 6px
          lg: "6px",
          xl: "6px",
        },
      },
      components: {
        card: {
          shadow:
            "0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12)",
        },
        badge: {
          sm: {
            fontSize: "0.625rem",
            minWidth: "1rem",
            height: "1rem",
          },
        },
        tag: {
          root: {
            roundedBorderRadius: "999px",
            fontWeight: "500",
          },
        },
        message: {
          colorScheme: {
            light: {
              info: {
                background: "color-mix(in srgb, {primary.50}, transparent 5%)",
                borderColor: "{primary.200}",
                color: "{primary.600}",
                shadow: "0px 4px 8px 0px color-mix(in srgb, {primary.500}, transparent 96%)",
              },
            },
          },
        },
      },
    },
    getPrimevueTheme(),
  )

  app.use(PrimeVue, {
    theme: {
      preset: definePreset(Aura, mergedStyles),
      options: {
        darkModeSelector: ".dark-mode",
        cssLayer: {
          name: "primevue",
        },
      },
    },
    ripple: true,
  })
  app.directive("tooltip", TooltipDirective)
  app.directive("ripple", Ripple)
  app.use(ToastService)
  app.use(DialogService)
  app.use(ConfirmationService)
}
