import { z } from "zod"
import { CurrencyCodes } from "@/commons/typings/currencies"
import { CartItemSchema } from "./cart"
import { OrganizationAddressSchema } from "./organization"

export const OrderStatusSchema = z.enum([
  "AWAITING_APPROVAL",
  "AWAITING_PAYMENT",
  "PAID",
  "REJECTED",
  "COMPLETED",
])

export type OrderStatusSchemaType = z.infer<typeof OrderStatusSchema>

export const OrderSchema = z.object({
  id: z.number(),
  userId: z.number(),
  address: OrganizationAddressSchema,
  credits: z.number(),
  price: z.object({
    net: z.number(),
    gross: z.number(),
    vatAmount: z.number(),
  }),
  pricePreDiscount: z.object({
    net: z.number(),
    gross: z.number(),
    vatAmount: z.number(),
  }),
  vatPercentage: z.number(),
  currency: CurrencyCodes,
  status: OrderStatusSchema,
  transactionId: z.string(),
  invoiceNumber: z.string(),
  invoiceName: z.string(),
  invoiceFileId: z.string(),
  paidAt: z.string().datetime(),
  createdAt: z.string().datetime(),
  companyName: z.string(),
  invoiceEmail: z.string(),
  vatNumber: z.string(),
  items: z.array(CartItemSchema),
})

export type OrderSchemaType = z.infer<typeof OrderSchema>
