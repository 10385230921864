import type { RouteRecordRaw } from "vue-router"
import LayoutBlank from "@/commons/layouts/LayoutBlank.vue"
import { MarketplaceRoutesDefinitions } from "@/marketplace/routes"

export const CommonRoutesDefinitions = {
  DEFAULT: { name: "commons/default" },
  ERROR: { name: "commons/error" },
  CATCH_ALL: { name: "commons/catch-all" },
} as const

export const commonRoutes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: CommonRoutesDefinitions.DEFAULT.name,
    redirect: MarketplaceRoutesDefinitions.PROJECT_LIST_CREDIT_SALE,
  },
  {
    path: "/error",
    name: CommonRoutesDefinitions.ERROR.name,
    component: () => import("./views/ViewGenericError.vue"),
    meta: {
      layout: LayoutBlank,
    },
  },
  {
    path: "/:pathMatch(.*)*",
    name: CommonRoutesDefinitions.CATCH_ALL.name,
    component: () => import("./views/ViewGenericError.vue"),
  },
]
