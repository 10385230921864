import axios from "axios"

export const auth = axios.create({
  baseURL: `${import.meta.env.VITE_API_URL}/auth`,
})

auth.interceptors.response.use(
  (response) => response,
  (error) => {
    // TODO: Show the error to the user
    console.error(error); // eslint-disable-line
    return Promise.reject(error)
  },
)
