import { z } from "zod"
import { LoginSchema } from "@/auth/typings/login"
import { OrganizationSchema } from "./../../marketplace/typings/organization"

export const UserRoleSchema = z.enum([
  "Admin",
  "Manager",
  "Member",
  "Viewer",
  "Awaiting Approval",
  "Blocked",
])

export type UserRole = z.infer<typeof UserRoleSchema>

export const UserPermissionSchema = z.enum([
  "LOGIN",
  "VIEW_PERSONAL_DETAILS",
  "SEARCH_MARKETPLACE",
  "READ_NOTIFICATIONS",
  "VIEW_ORDERS",
  "PURCHASE",
  "VIEW_PORTFOLIO",
  "RETIRE_CREDITS",
  "VIEW_REPORTS",
  "VIEW_ORGANIZATION_DETAILS",
  "EDIT_ORGANIZATION_DETAILS",
  "VIEW_ORGANIZATION_MEMBERS",
  "MANAGE_ORGANIZATION_MEMBERS",
  "ADMINISTRATION",
])

export type UserPermission = z.infer<typeof UserPermissionSchema>

export const RoleBaseSchema = z.object({
  id: z.number(),
  name: UserRoleSchema,
  permissions: z.array(UserPermissionSchema),
})

export type RoleBaseSchema = z.infer<typeof RoleBaseSchema>

export const UserBaseSchema = LoginSchema.pick({ username: true }).extend({
  id: z.number().optional(),
  firstName: z.string(),
  lastName: z.string(),
  phone: z.string().optional(),
  avatar: z.string().optional(),
  role: RoleBaseSchema,
  organization: OrganizationSchema,
  blocked: z.boolean().optional(),
  pendingInvitation: z.boolean().optional(),
  registeredAt: z.date().optional(),
  lastLoginAt: z.date().optional(),
  lastPurchaseAt: z.date().optional(),
  totalPurchasedVolume: z.number().optional(),
  referalPartnerId: z.number().optional(),
  referalExternalId: z.string().optional(),
})

export type UserBaseSchema = z.infer<typeof UserBaseSchema>
