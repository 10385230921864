<script setup lang="ts">
import PrimeDrawer from "primevue/drawer"
import { ref, watch } from "vue"
import { SIDEBAR_CLASSES } from "@/commons/components/TheSidebarMenu.vue"
import UiButton from "@/ui/atoms/UiButton.vue"
import UiSpinner from "@/ui/atoms/UiSpinner.vue"
import { useNotificationsStore } from "../composables/use-notifications"
import SmartNotification from "./SmartNotification.vue"

const open = defineModel<boolean>("visible", { required: true })

const notificationsStore = useNotificationsStore()

const currentNotificationPage = ref(0)
const loadMoreNotifications = () => {
  currentNotificationPage.value += 1
  notificationsStore.fetchReadNotifications(currentNotificationPage.value)
}

watch(open, (value) => {
  if (value) {
    currentNotificationPage.value = 0
    notificationsStore.fetchReadNotifications()
  }
})

const close = () => {
  open.value = false
}
</script>

<template>
  <prime-drawer
    v-model:visible="open"
    class="!w-full !md:w-2xl"
    :auto-z-index="false"
    :pt="{
      mask: {
        class: SIDEBAR_CLASSES.notificationsSidebar,
      },
    }"
  >
    <div class="flex items-center justify-between gap-16">
      <h1 class="v-text-h5">Notifications</h1>
      <ui-button
        text
        plain
        size="small"
        icon="i-mdi-check-all"
        label="Mark all as read"
        @click="notificationsStore.markAllAsRead()"
      />
    </div>

    <section>
      <h1 class="mt-8 text-lg">Unread</h1>

      <div class="mt-4">
        <template v-if="notificationsStore.unreadNotifications.length === 0">
          <p class="text-center text-dimmed">No unread notifications</p>
        </template>
        <template v-else>
          <smart-notification
            v-for="notification in notificationsStore.unreadNotifications"
            :key="notification.id"
            :notification="notification"
            :close="close"
          >
            <template #actions>
              <ui-button
                v-tooltip.bottom="{ value: 'Mark as read', showDelay: 1000 }"
                icon="i-mdi-check"
                text
                plain
                rounded
                aria-label="Mark as read"
                @click="notificationsStore.markAsRead(notification)"
              />
            </template>
          </smart-notification>
        </template>
      </div>
    </section>

    <section>
      <h2 class="mt-8 text-lg">Read</h2>

      <div class="mt-4 relative">
        <template v-if="notificationsStore.isFetchingReadNotifications">
          <div class="flex justify-center absolute bottom-5 w100%">
            <ui-spinner />
          </div>
        </template>
        <template v-if="notificationsStore.readNotifications.length === 0">
          <p class="text-center text-dimmed">No read notifications</p>
        </template>
        <template v-else>
          <smart-notification
            v-for="notification in notificationsStore.readNotifications"
            :key="notification.id"
            :notification="notification"
            :close="close"
            class="opacity-50"
          />
          <div class="text-right">
            <ui-button
              v-if="notificationsStore.hasMoreReadNotifications"
              text
              plain
              size="small"
              label="Load more"
              @click="loadMoreNotifications"
            />
          </div>
        </template>
      </div>
    </section>
  </prime-drawer>
</template>

<style lang="scss"></style>
