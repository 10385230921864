import { useLocalStorage } from "@vueuse/core"
import { callRefreshTokens } from "@/auth/api/auth-api"
import type { LoginResponseTokens } from "@/auth/typings/login"
import { isTokenValid } from "@/auth/utils/token"

export const useTokens = () => {
  const store = useLocalStorage("auth-storage", { authToken: "", refreshToken: "" })

  const getAuthToken = () => store.value.authToken
  const getRefreshToken = () => store.value.refreshToken

  const setTokens = (tokens: LoginResponseTokens) => {
    store.value = tokens
  }

  const clearTokens = () => {
    store.value = { authToken: "", refreshToken: "" }
  }

  const refreshTokens = async () => {
    const tokens = await callRefreshTokens(getRefreshToken())
    setTokens(tokens)
  }

  return {
    getAuthToken,
    getRefreshToken,
    setTokens,
    clearTokens,
    isTokenValid,
    refreshTokens,
  }
}
