import { http } from "@/commons/utils/axios-instances"
import type { CountryCodeSchemaType } from "@/commons/utils/countries"
import type { CheckoutSchemaType } from "../typings/organization"
import type { CartSchemaType } from "./../typings/cart"

export const fetchCart = (countryCode?: CountryCodeSchemaType) =>
  http
    .get<CartSchemaType>("/me/cart", { params: countryCode ? { countryCode } : {} })
    .then((res) => res.data)

export const callCartAddVintage = (
  data: { vintageId: number; credits: number },
  countryCode?: CountryCodeSchemaType,
) =>
  http
    .post<CartSchemaType>("/me/cart/vintages", data, { params: countryCode ? { countryCode } : {} })
    .then((res) => res.data)

export const callCartRemoveVintage = (
  data: { vintageId: number },
  countryCode?: CountryCodeSchemaType,
) =>
  http
    .delete<CartSchemaType>("/me/cart/vintages", {
      data,
      params: countryCode ? { countryCode } : {},
    })
    .then((res) => res.data)

export const callCartAddReport = (
  data: { reportId: number },
  countryCode?: CountryCodeSchemaType,
) =>
  http
    .post<CartSchemaType>("/me/cart/reports", data, { params: countryCode ? { countryCode } : {} })
    .then((res) => res.data)

export const callCartRemoveReport = (
  data: { reportId: number },
  countryCode?: CountryCodeSchemaType,
) =>
  http
    .delete<CartSchemaType>("/me/cart/reports", {
      data,
      params: countryCode ? { countryCode } : {},
    })
    .then((res) => res.data)

export const callCartCheckout = (data: CheckoutSchemaType) =>
  http.post("/me/cart/checkout", data).then((res) => res.data)
