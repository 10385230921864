import type { NavigationGuard, RouteLocationRaw } from "vue-router"
import { useUserStore } from "@/organization/composables/use-user"

type NavigationGuardFactory<T> = (options: T) => NavigationGuard

type IsTokenExpiredGuardOptions = {
  redirectTo: RouteLocationRaw
}

export const isTokenExpiredGuard: NavigationGuardFactory<IsTokenExpiredGuardOptions> =
  ({ redirectTo }) =>
  (to, from, next) => {
    const { expireAt } = to.query
    const now = Date.now()

    if (!expireAt || now > Number(expireAt)) {
      return next(redirectTo)
    }

    next()
  }

type IsTokenInQueryGuardOptions = {
  redirectTo: RouteLocationRaw
}

export const isTokenInQueryGuard: NavigationGuardFactory<IsTokenInQueryGuardOptions> =
  ({ redirectTo }) =>
  (to, from, next) => {
    if (!to.query.token) {
      return next(redirectTo)
    }
    next()
  }

type ValidateTokenGuardOptions = {
  redirectTo: RouteLocationRaw
  validator: (token: string) => Promise<any>
}

export const validateTokenGuard: NavigationGuardFactory<ValidateTokenGuardOptions> =
  ({ validator, redirectTo }) =>
  async (to, from, next) => {
    try {
      await validator(to.query.token as string)
      next()
    } catch (error) {
      next(redirectTo)
    }
  }

type BookDemoGuardOptions = {
  redirectTo: RouteLocationRaw
}

export const bookDemoGuard: NavigationGuardFactory<BookDemoGuardOptions> =
  ({ redirectTo }) =>
  (to, from, next) => {
    const user = useUserStore()
    if (user.isLoggedIn && !user.hasRole("Awaiting Approval")) return next(redirectTo)
    next()
  }
