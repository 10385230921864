<script setup lang="ts">
import { defineAsyncComponent, h } from "vue"
import type { Component } from "vue"
import { scheduleSentryAction } from "@/commons/plugins/sentry"
import type { Notification } from "@/notifications/composables/use-notifications"

defineProps<{
  notification: Notification
  close: () => void
}>()

const NOTIFICATION_COMPONENTS: Record<string, Component> = {
  // Cart
  "cart.claim.type.changed": defineAsyncComponent(
    () => import("@/notifications/components/NotificationCartClaimTypeChanged.vue"),
  ),
  "cart.impact.type.changed": defineAsyncComponent(
    () => import("@/notifications/components/NotificationCartImpactTypeChanged.vue"),
  ),
  "cart.vintage.price.changed": defineAsyncComponent(
    () => import("@/notifications/components/NotificationCartPriceChanged.vue"),
  ),
  "cart.project.temporarily.unavailable": defineAsyncComponent(
    () => import("@/notifications/components/NotificationCartProjectTemporarilyUnavailable.vue"),
  ),
  "cart.vintage.removed": defineAsyncComponent(
    () => import("@/notifications/components/NotificationCartVintageRemoved.vue"),
  ),
  "cart.vintage.credit.ex-post": defineAsyncComponent(
    () => import("@/notifications/components/NotificationCartVintageCreditExPost.vue"),
  ),
  "cart.vintage.credit.ex-ante": defineAsyncComponent(
    () => import("@/notifications/components/NotificationCartVintageCreditExAnte.vue"),
  ),
  "cart.vintage.year.changed": defineAsyncComponent(
    () => import("@/notifications/components/NotificationCartVintageYearChanged.vue"),
  ),
  "cart.report.removed": defineAsyncComponent(
    () => import("@/notifications/components/NotificationCartReportRemoved.vue"),
  ),
  "cart.report.price.changed": defineAsyncComponent(
    () => import("@/notifications/components/NotificationCartReportPriceChanged.vue"),
  ),
  // Order
  "order.approved": defineAsyncComponent(
    () => import("@/notifications/components/NotificationOrderApproved.vue"),
  ),
  "order.created": defineAsyncComponent(
    () => import("@/notifications/components/NotificationOrderCreated.vue"),
  ),
  "order.paid": defineAsyncComponent(
    () => import("@/notifications/components/NotificationOrderPaid.vue"),
  ),
  "order.rejected": defineAsyncComponent(
    () => import("@/notifications/components/NotificationOrderRejected.vue"),
  ),
  // Portfolio
  "portfolio.retirement.completed": defineAsyncComponent(
    () => import("@/notifications/components/NotificationPortfolioRetirementCompleted.vue"),
  ),
  "portfolio.retirement.requested": defineAsyncComponent(
    () => import("@/notifications/components/NotificationPortfolioRetirementRequested.vue"),
  ),
  "portfolio.vintage.credit.ex-post": defineAsyncComponent(
    () => import("@/notifications/components/NotificationPortfolioVintageCreditExPost.vue"),
  ),
  "portfolio.vintage.credit.ex-ante": defineAsyncComponent(
    () => import("@/notifications/components/NotificationPortfolioVintageCreditExAnte.vue"),
  ),
  "portfolio.retirement.certificate.available": defineAsyncComponent(
    () =>
      import("@/notifications/components/NotificationPortfolioRetirementCertificateAvailable.vue"),
  ),
  "portfolio.credits.imported": defineAsyncComponent(
    () => import("@/notifications/components/NotificationPortfolioCreditsImported.vue"),
  ),
  "portfolio.credits.subtracted": defineAsyncComponent(
    () => import("@/notifications/components/NotificationPortfolioCreditsSubtracted.vue"),
  ),
  // User
  "user.password.changed": defineAsyncComponent(
    () => import("@/notifications/components/NotificationUserPasswordChanged.vue"),
  ),
  // Report
  "report.access.granted": defineAsyncComponent(
    () => import("@/notifications/components/NotificationReportAccessGranted.vue"),
  ),
  "report.version.released": defineAsyncComponent(
    () => import("@/notifications/components/NotificationReportVersionReleased.vue"),
  ),
  // Organization
  "organization.name.change.requested": defineAsyncComponent(
    () => import("@/notifications/components/NotificationOrganizationNameChangeRequested.vue"),
  ),
  "organization.name.changed": defineAsyncComponent(
    () => import("@/notifications/components/NotificationOrganizationNameChanged.vue"),
  ),
}

const getNotificationComponent = (notification: Notification) => {
  const component = NOTIFICATION_COMPONENTS[notification.code]

  if (!component) {
    scheduleSentryAction((instance) => {
      instance.captureEvent({
        message: `Notification component not found for type: ${notification.code}`,
        level: "error",
      })
    })

    console.warn(`Notification component not found for type: ${notification.code}`)

    return h("div") // Mock component
  }

  return component
}
</script>

<template>
  <component :is="getNotificationComponent(notification)" v-bind="notification" :close="close">
    <template #actions>
      <slot name="actions" />
    </template>
  </component>
</template>

<style scoped lang="scss"></style>
